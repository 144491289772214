import React, { useEffect, useState } from 'react';
import './EditPage.css';
import TextItem from "../TextItem/TextItem";
import TitleItem from "../TitleItem/TitleItem";
import ImgItem from "../ImgItem/ImgItem";
import PdfItem from "../PdfItem/PdfItem";
import GalleryItem from "../GalleryItem/GalleryItem";
import CampingPricesItem from "../CampingPricesItem/CampingPricesItem";
import PlacesPricesItem from "../PlacesPricesItem/PlacesPricesItem";
import SeasonDateItem from "../SeasonDateItem/SeasonDateItem";
import InfosItem from "../InfosItem/InfosItem";
import MiscPricesItem from "../MiscPricesItem/MiscPricesItem";
import Spinner from "../Spinner/Spinner";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from "date-fns/locale/fr";

function EditPage({ page }) {

    const [editItems, setEditItems] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        setLoading(true)

        fetch(`${process.env.REACT_APP_API_URL || 'http://localhost:4000'}/api/edit-item/page?page=${page}`, {
            headers: {
                'Authorization': 'JWT ' + sessionStorage.getItem('token')
            }
        })
            .then((res) => res.json())
            .then(json => {
                if (json.status === 200) {
                    json.editItems.sort(function compare(a, b) {
                        /*
                        let refTab = ["text-item", "img-item", "title-item"]
    
                        if (refTab.indexOf(a.type) < refTab.indexOf(b.type))
                            return -1;
                        if (refTab.indexOf(a.type) > refTab.indexOf(b.type))
                            return 1;
                        */

                        if (a.order < b.order)
                            return -1;
                        if (a.order > b.order)
                            return 1;

                        return 0;
                    })

                    setEditItems(json.editItems)
                    setLoading(false)
                }
            })
    }, [page]);

    let pageName;

    switch (page) {
        case "accueil":
            pageName = "Accueil";
            break;
        case "chalet-bois":
            pageName = "Chalet en Bois";
            break;
        case "chalet-rondin":
            pageName = "Chalet en Rondin";
            break;
        case "mobile-home":
            pageName = "Mobile-Home";
            break;
        case "ecolodges":
            pageName = "Ecolodges";
            break;
        case "emplacements":
            pageName = "Emplacements";
            break;
        case "restaurant":
            pageName = "Restaurant";
            break;
        case "activites":
            pageName = "Activités";
            break;
        case "tarifs":
            pageName = "Tarifs";
            break;
        case "divers":
            pageName = "Divers";
            break;
        case "comptes":
            pageName = "Comptes";
            break;
        default:
            pageName = "";

    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
            <div id="edit-page">
                <h1>{pageName}</h1>

                {
                    loading ? <Spinner /> :
                        editItems.map(function (item, i) {
                            switch (item.type) {
                                case "text-item":
                                    return <TextItem key={i} id={item.itemId} title={item.title} file={item.file} jsonKey={item.key} />
                                case "title-item":
                                    return <TitleItem key={i} id={item.itemId} title={item.title} file={item.file} jsonKey={item.key} />
                                case "img-item":
                                    return <ImgItem key={i} id={item.itemId} title={item.title} file={item.file} jsonKey={item.key} />
                                case "pdf-item":
                                    return <PdfItem key={i} id={item.itemId} title={item.title} file={item.file} jsonKey={item.key} />
                                case "gallery-item":
                                    return <GalleryItem key={i} id={item.itemId} title={item.title} file={item.file} jsonKey={item.key} />
                                case "camping-prices-item":
                                    return <CampingPricesItem key={i} id={item.itemId} title={item.title} file={item.file} jsonKey={item.key} />
                                case "misc-prices-item":
                                    return <MiscPricesItem key={i} id={item.itemId} title={item.title} file={item.file} jsonKey={item.key} />
                                case "places-prices-item":
                                    return <PlacesPricesItem key={i} id={item.itemId} title={item.title} file={item.file} jsonKey={item.key} />
                                case "date-item":
                                    return <SeasonDateItem key={i} id={item.itemId} title={item.title} file={item.file} jsonKey={item.key} />
                                case "infos-item":
                                    return <InfosItem key={i} id={item.itemId} title={item.title} file={item.file} jsonKey={item.key} />
                                default:
                                    return <p key={i}>Not Implemented Type of Item</p>
                            }
                        })
                }

            </div>
        </MuiPickersUtilsProvider>
    )

}

export default EditPage;