import React from 'react';
import { useTranslation } from 'react-i18next';
import { getHtmlString } from '../../utils/parseHtmlString';
import './CGV.css';

function CGV() {

    const { t } = useTranslation('cgv');

    return (
        <div className='container cgv'>
            <h2>{t("title")}</h2>

            <div dangerouslySetInnerHTML={getHtmlString(t('content'))}></div>
        </div>
    )
}

export default CGV;