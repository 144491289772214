import React, { useState, useContext } from 'react';
import './AdminLogin.css';
import { NotificationContext } from "../NotificationProvider/NotificationProvider";
import { v4 } from "uuid";

function AdminLogin({ setLoggedIn }) {

    const [emailInput, setEmailInput] = useState("");
    const [passwordInput, setPasswordInput] = useState("");

    const [updatePassword, setUpdatePassword] = useState(false);
    const [newPasswordInput, setNewPasswordInput] = useState("");
    const [confirmPasswordInput, setConfirmPasswordInput] = useState("");

    const dispatch = useContext(NotificationContext);

    const handleEmailInputChange = (event) => {
        setEmailInput(event.target.value)
    }

    const handlePasswordInputChange = (event) => {
        setPasswordInput(event.target.value)
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!updatePassword) {
            fetch(`${process.env.REACT_APP_API_URL || 'http://localhost:4000'}/api/auth/signin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: encodeURI('email') + "=" + encodeURI(emailInput) + "&" + encodeURI('password') + "=" + encodeURI(passwordInput)
            })
            .then((res) => res.json())
            .then(json => {
                if (json.status === 200) {
                    sessionStorage.setItem('token', json.token);

                    if (json.reinitPassword) setUpdatePassword(true)
                    else setLoggedIn()
                }
                else if (json.status === 401) {
                    dispatch({
                        type: "ADD_NOTIFICATION",
                        payload: {
                            id: v4(),
                            type: "ERROR",
                            message: json.message
                        }
                    })
                }
                else {
                    dispatch({
                        type: "ADD_NOTIFICATION",
                        payload: {
                            id: v4(),
                            type: "ERROR",
                            message: "Un problème est survenu"
                        }
                    })
                }
            })
            .catch(err => {
                console.log(err);

                dispatch({
                    type: "ADD_NOTIFICATION",
                    payload: {
                        id: v4(),
                        type: "ERROR",
                        message: "L'API n'est pas atteignable"
                    }
                })
            });
        }
        else {
            fetch(`${process.env.REACT_APP_API_URL || 'http://localhost:4000'}/api/auth/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'JWT ' + sessionStorage.getItem('token')
                },
                body: encodeURI('newPassword') + "=" + encodeURI(newPasswordInput) + "&" + encodeURI('confirmPassword') + "=" + encodeURI(confirmPasswordInput)
            })
            .then((res) => res.json())
            .then(json => {
                if (json.status === 200) {
                    sessionStorage.setItem('token', json.token);
                    
                    setLoggedIn()
                }
            })
        }

        // Inscription
        /*fetch(`${process.env.REACT_APP_API_URL || 'http://localhost:4000'}/api/auth/signup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              },
            body: encodeURI('email') + "=" + encodeURI(emailInput) + "&" + encodeURI('password') + "=" + encodeURI(passwordInput)
        })
        .then((res) => res.json())
        .then(json => {
            console.log(json);
        })*/
    }

    return (
        <div id="admin-login">
            {
                !updatePassword 
                ? 
                <form onSubmit={handleSubmit}>
                    <img src='/img/logo_text.png' alt='' />

                    <div className="input">
                        <label htmlFor="signinEmail">Email</label>
                        <input type="email" id="signinEmail" name="signinEmail" value={emailInput} onChange={handleEmailInputChange} placeholder='email@example.com' />
                    </div>

                    <div className="input">
                        <label htmlFor="signinPassword">Mot de passe</label>
                        <input type="password" id="signinPassword" name="signinPassword" value={passwordInput} onChange={handlePasswordInputChange} placeholder='Mot de passe' />
                    </div>

                    <button type='submit'>Valider</button>
                </form>
                : 
                <form onSubmit={handleSubmit}>
                    <img src='/img/logo_text.png' alt='' />

                    <div className="input">
                        <label htmlFor="newPassword">Nouveau mot de passe</label>
                        <input type="password" id="newPassword" name="newPassword" value={newPasswordInput} onChange={(event) => setNewPasswordInput(event.target.value)} placeholder='Nouveau mot de passe' />
                    </div>

                    <div className="input">
                        <label htmlFor="confirmPassword">Confirmer Mot de passe</label>
                        <input type="password" id="confirmPassword" name="confirmPassword" value={confirmPasswordInput} onChange={(event) => setConfirmPasswordInput(event.target.value)} placeholder='Confirmer mot de passe' />
                    </div>

                    <button type='submit'>Valider</button>
                </form>
            }
        </div> 
    )

}

export default AdminLogin;