import React, { useEffect } from 'react';
import {
    Switch,
    Route,
    withRouter
} from "react-router-dom";

import { TransitionGroup, CSSTransition } from "react-transition-group";
import $ from 'jquery';
import { useTranslation } from 'react-i18next';
import Navbar from '../Navbar/Navbar';
import Home from '../Home/Home';
import Camping from '../Camping/Camping';
import Restaurant from '../Restaurant/Restaurant';
import RestaurantMenu from '../RestaurantMenu/RestaurantMenu';
import Activities from '../Activities/Activities';
import Booking from '../Booking/Booking';
import Infos from '../Infos/Infos';
import CGV from '../CGV/CGV';
import Footer from '../Footer/Footer';
import Admin from '../Admin/Admin';
import ImageProvider from '../ImageProvider/ImageProvider';

function updatePageTitle(path, t) {
    let title;

    switch (path) {
        case '/':
            title = t('home');
            break;
        case '/camping/chalet-bois':
            title = t("camping.chalet-bois");
            break;
        case '/camping/chalet-rondins':
            title = t("camping.chalet-rondin");
            break;
        case '/camping/mobile-home':
            title = t("camping.mobile-home");
            break;
        case '/camping/ecolodges':
            title = t("camping.ecolodge");
            break;
        case '/camping/emplacements':
            title = t("camping.emplacement");
            break;
        case '/restaurant/contactless-menu':
        case '/restaurant':
            title = t("restaurant");
            break;
        case '/activites':
            title = t("activities");
            break;
        case '/reserver':
            title = t("book");
            break;
        case '/infos':
            title = t("infos");
            break;
        case '/cgv':
            title = t("cgv");
            break;
        case '/admin':
            title = "Administration";
            break;
        default:
            title = t("default");
    }

    document.title = title;
}

function Container({ location }) {

    const { t } = useTranslation('page-names');

    useEffect(() => {
        $('html, body').animate({
            scrollTop: 0
        }, 500);

        updatePageTitle(location.pathname, t);
    });

    return (
        <div>

            <Switch location={location}>
                <Route path="/admin">
                    <Admin />
                </Route>

                <Route path="/">
                    <ImageProvider>
                        <Navbar path={location.pathname} />

                        <TransitionGroup className="transition-group">
                            <CSSTransition
                                key={location.key}
                                timeout={{ enter: 550, exit: 550 }}
                                classNames={'fade'}>

                                <section className='route-section'>
                                    <Switch location={location}>
                                        <Route path="/camping/:type">
                                            <Camping />
                                        </Route>
                                        <Route path="/restaurant/contactless-menu">
                                            <RestaurantMenu />
                                        </Route>
                                        <Route path="/restaurant">
                                            <Restaurant />
                                        </Route>
                                        <Route path="/activites">
                                            <Activities />
                                        </Route>
                                        <Route path="/reserver">
                                            <Booking />
                                        </Route>
                                        <Route path="/infos">
                                            <Infos />
                                        </Route>
                                        <Route path="/cgv">
                                            <CGV />
                                        </Route>
                                        <Route path="/">
                                            <Home />
                                        </Route>
                                    </Switch>

                                    <Footer />
                                </section>

                            </CSSTransition>
                        </TransitionGroup>
                    </ImageProvider>
                </Route>

            </Switch>
        </div>
    );
}

export default withRouter(Container);
