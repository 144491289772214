import React from 'react';
import './CampingContent.css';

function CampingElement({ leftSide, rightSide }) {

    return (
        <div className='entry'>
            {leftSide}

            {rightSide}
        </div>
    )
}

export default CampingElement;