import React, { useContext } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTranslation } from 'react-i18next';
import { getHtmlString } from '../../utils/parseHtmlString';
import './Restaurant.css';
import FancyTitle from '../FancyTitle/FancyTitle';
import MenuEntry from '../MenuEntry/MenuEntry';
import Gallery from '../Gallery/Gallery';
import { ImageContext } from '../ImageProvider/ImageProvider';

function Restaurant() {

    const { t } = useTranslation('restaurant');

    const images = useContext(ImageContext);

    let photos = images.restaurant.galleries.restaurant

    return (
        <div id='restaurant' className='container'>
            <div className='intro'>
                <div>
                    <FancyTitle firstText={t('title.top')} secondText={t('title.bottom')} color='blue' />
                    <div dangerouslySetInnerHTML={getHtmlString(t('text'))}></div>
                </div>

                <div className='image'>
                    <LazyLoadImage src={images.restaurant.main.img} alt={images.restaurant.main.alt} />
                </div>

                <div className='icon'>
                    <img src='/img/restaurant/icon.png' alt='Icone' />
                </div>
            </div>

            <div className='menu'>
                <div className='title'>
                    <h2>{t('menu.title')}</h2>
                </div>

                <div className='content'>
                    <div className='text'>
                        <MenuEntry alignment='left' firstText={t('menu.1.top')} secondText={t('menu.1.bottom')} />
                        <MenuEntry alignment='left' firstText={t('menu.2.top')} secondText={t('menu.2.bottom')} />
                    </div>

                    <div className='text'>
                        <MenuEntry alignment='right' firstText={t('menu.3.top')} secondText={t('menu.3.bottom')} />
                        <MenuEntry alignment='right' firstText={t('menu.4.top')} secondText={t('menu.4.bottom')} />
                    </div>

                    <div className='text'>
                        <MenuEntry alignment='left' firstText={t('menu.5.top')} secondText={t('menu.5.bottom')} />
                        <MenuEntry alignment='left' firstText={t('menu.6.top')} secondText={t('menu.6.bottom')} />
                    </div>

                    <div className='image'>
                        <LazyLoadImage src={images.restaurant.menu.first.img} alt={images.restaurant.menu.first.alt} />
                    </div>

                    <div className='image'>
                        <LazyLoadImage src={images.restaurant.menu.second.img} alt={images.restaurant.menu.second.alt} />
                    </div>

                    <div className='image'>
                        <LazyLoadImage src={images.restaurant.menu.third.img} alt={images.restaurant.menu.third.alt} />
                    </div>
                </div>
            </div>

            <div className='concert'>
                <div className='image'>
                    <LazyLoadImage src={images.restaurant.concert.img} alt={images.restaurant.concert.alt} />
                </div>
                
                <div>
                    <FancyTitle firstText={t('concert.title.top')} secondText={t('concert.title.bottom')} color='blue' />
                    <div dangerouslySetInnerHTML={getHtmlString(t('concert.text'))}></div>
                </div>
            </div>

            <Gallery photos={photos} color='blue' />
        </div>
    )
}

export default Restaurant;