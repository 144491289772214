import React, { useEffect, useState, useContext } from 'react';
import './AccountMgt.css';
import { NotificationContext } from '../NotificationProvider/NotificationProvider';
import { v4 } from 'uuid';

function AccountMgt() {

    const [ users, setUsers ] = useState([])
    const [ email, setEmail ] = useState("")
    const [ modal, setModal ] = useState(false)
    const [ tmpPassword, setTmpPassword ] = useState("")

    const dispatch = useContext(NotificationContext)

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL || 'http://localhost:4000'}/api/auth/all`, {
            headers: {
                'Authorization': 'JWT ' + sessionStorage.getItem('token')
            }
        })
        .then((res) => res.json())
        .then(json => {
            if (json.status === 200) {
                setUsers(json.users)
            }
        })
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault();

        fetch(`${process.env.REACT_APP_API_URL || 'http://localhost:4000'}/api/auth/signup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'JWT ' + sessionStorage.getItem('token')
            },
            body: "email=" + encodeURI(email)
        })
        .then((res) => res.json())
        .then(json => {
            if (json.status === 200) {
                console.log(json.message)
                users.push({ email: email, reinitPassword: true, role: "admin" })
                setEmail("");
                setTmpPassword(json.tmpPassword)
                setModal(true)
                dispatch({
                    type: "ADD_NOTIFICATION",
                    payload: {
                        id: v4(),
                        type: "SUCCESS",
                        message: "Utilisateur créé"
                    }
                })
            }
            else {
                console.log(json.error)
                dispatch({
                    type: "ADD_NOTIFICATION",
                    payload: {
                        id: v4(),
                        type: "ERROR",
                        message: "Un problème est survenu"
                    }
                })
            }
        })
        .catch((err) => {
            dispatch({
                type: "ADD_NOTIFICATION",
                payload: {
                    id: v4(),
                    type: "ERROR",
                    message: "Un problème est survenu"
                }
            })
        })
    }

    const closeModal = () => {
        setModal(false);
        setTmpPassword("");
    }

    const handleDelete = (email) => {
        fetch(`${process.env.REACT_APP_API_URL || 'http://localhost:4000'}/api/auth/delete`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'JWT ' + sessionStorage.getItem('token')
            },
            body: "email=" + encodeURI(email)
        })
        .then((res) => res.json())
        .then(json => {
            if (json.status === 200) {
                setUsers(users.filter(el => el.email !== email))
                dispatch({
                    type: "ADD_NOTIFICATION",
                    payload: {
                        id: v4(),
                        type: "SUCCESS",
                        message: "Utilisateur supprimé"
                    }
                })
            }
            else {
                console.log(json.error)
                dispatch({
                    type: "ADD_NOTIFICATION",
                    payload: {
                        id: v4(),
                        type: "ERROR",
                        message: "Un problème est survenu"
                    }
                })
            }
        })
        .catch((err) => {
            dispatch({
                type: "ADD_NOTIFICATION",
                payload: {
                    id: v4(),
                    type: "ERROR",
                    message: "Un problème est survenu"
                }
            })
        })
    }

    const handleReset = (email) => {
        fetch(`${process.env.REACT_APP_API_URL || 'http://localhost:4000'}/api/auth/reset`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'JWT ' + sessionStorage.getItem('token')
            },
            body: "email=" + encodeURI(email)
        })
        .then((res) => res.json())
        .then(json => {
            if (json.status === 200) {
                const index = users.findIndex(el => el.email === email)
                users[index].reinitPassword = true
                setTmpPassword(json.tmpPassword)
                setModal(true)
                dispatch({
                    type: "ADD_NOTIFICATION",
                    payload: {
                        id: v4(),
                        type: "SUCCESS",
                        message: "Mot de passe réinitialisé"
                    }
                })
            }
            else {
                console.log(json.error)
                dispatch({
                    type: "ADD_NOTIFICATION",
                    payload: {
                        id: v4(),
                        type: "ERROR",
                        message: "Un problème est survenu"
                    }
                })
            }
        })
        .catch((err) => {
            dispatch({
                type: "ADD_NOTIFICATION",
                payload: {
                    id: v4(),
                    type: "ERROR",
                    message: "Un problème est survenu"
                }
            })
        })
    }

    return (
        <div id="account-mgt">
            <h1>Gestion des Utilisateurs</h1>

            <div className="list">
                <div className="legend">
                    <p>Email</p>
                    <p>Mot de passe</p>
                    <p>Role</p>
                    <p>Actions</p>
                </div>
                {
                    users.map((user, i) => {
                        return (
                            <div key={i} className="user-item">
                                <p className="id">{`#${i+1}`}</p>
                                <p className="email">{user.email}</p>
                                <p className={`password ${user.reinitPassword ? "change" : "definitive"}`}>{user.reinitPassword ? "À changer à la 1ère connexion" : "Définitif"}</p>
                                <p className={`role ${user.role}`}>{user.role.charAt(0).toUpperCase() + user.role.slice(1)}</p>
                                <div className="actions">
                                    <button className="reset" onClick={() => handleReset(user.email)}><i className="lni lni-key"></i></button>
                                    <button className="delete" onClick={() => handleDelete(user.email)}><i className="lni lni-trash"></i></button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <h1>Nouvel utilisateur</h1>

            <div className="form">
                <form onSubmit={handleSubmit}>
                    <div className="input">
                        <label htmlFor="signinEmail">Email</label>
                        <input type="email" id="signinEmail" name="signinEmail" value={email} onChange={(event) => setEmail(event.target.value)} placeholder='email@example.com' />
                    </div>

                    <button type='submit'>Valider</button>
                </form>
            </div>

            {
                modal &&
                <div className="modal">
                    <div>
                        <h2>Mot de passe temporaire</h2>
                        <p>{tmpPassword}</p>
                        <button onClick={closeModal}>OK</button>
                    </div>
                </div>
            }
        </div>
    )
}

export default AccountMgt;