import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './BookingWidget.css';

function BookingWidget() {

    const { t, i18n } = useTranslation('home');

    useEffect(() => {
        window.initBookingWidget(i18n.resolvedLanguage);
    })

    return (
        <div className='booking-widget'>
            <form action='https://naxiresa.inaxel.com/etape1-criteres.php' autoComplete='off' target='_blank'>
                <div className='input-group'>
                    <div className='input'>
                        <input type='text' id='datepickerArrivalDate' placeholder={t('landing-screen.booking-widget.arrival')} readOnly="readonly" />
                        <input type='hidden' id='datepickerArrivalDateString' name='debut' />
                    </div>

                    <div className='input'>
                        <input type='text' id='datepickerDepartureDate' placeholder={t('landing-screen.booking-widget.departure')} readOnly="readonly" />
                        <input type='hidden' id='datepickerDepartureDateString' name='fin' />
                    </div>

                    <div className='input'>
                        <select id='selectAccomodation' name='type' defaultValue='1'>
                            <option value='1'>{t('landing-screen.booking-widget.type.emplacement')}</option>
                            <option value='2'>{t('landing-screen.booking-widget.type.rental')}</option>
                        </select>
                    </div>

                    <div className='input'>
                        <select id='selectPersonsNumber' name='nbpers' defaultValue=''>
                            <option disabled value=''>{t('landing-screen.booking-widget.person-nbr.placeholder')}</option>
                            <option value='1'>1 {t('landing-screen.booking-widget.person-nbr.person')}</option>
                            <option value='2'>2 {t('landing-screen.booking-widget.person-nbr.person')}s</option>
                            <option value='3'>3 {t('landing-screen.booking-widget.person-nbr.person')}s</option>
                            <option value='4'>4 {t('landing-screen.booking-widget.person-nbr.person')}s</option>
                            <option value='5'>5 {t('landing-screen.booking-widget.person-nbr.person')}s</option>
                            <option value='6'>6 {t('landing-screen.booking-widget.person-nbr.person')}s</option>
                            <option value='7'>7 {t('landing-screen.booking-widget.person-nbr.person')}s</option>
                            <option value='8'>8 {t('landing-screen.booking-widget.person-nbr.person')}s</option>
                            <option value='9'>+ 8 {t('landing-screen.booking-widget.person-nbr.person')}s</option>
                        </select>
                    </div>

                    <input type="hidden" name="compte" value="rondinbois"></input>
                    <input type="hidden" name="source" value="etape2"></input>
                    <input type="hidden" id="wdng_lang" name="lang" value={(i18n.resolvedLanguage === 'fr' ? '1' : '2')}></input>
                </div>
                <input className='submit-button' type='submit' value={t('landing-screen.booking-widget.button')}></input>
            </form>
        </div>
    )
}

export default BookingWidget