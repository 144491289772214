import React, { useEffect, useState, useContext } from 'react';
import './AdminPanel.css';
import EditPage from "../EditPage/EditPage";
import AccountMgt from "../AccountMgt/AccountMgt";
import { NotificationContext } from '../NotificationProvider/NotificationProvider';
import { v4 } from "uuid";

function AdminPanel({ logout }) {

    const [page, setPage] = useState("accueil");
    const [loginCheck, setLoginCheck] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    const dispatch = useContext(NotificationContext);

    useEffect(() => {
        if (sessionStorage.getItem('token')) {
            fetch(`${process.env.REACT_APP_API_URL || 'http://localhost:4000'}/api/auth/verify`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: encodeURI('token') + "=" + encodeURI(sessionStorage.getItem('token'))
            })
                .then((res) => res.json())
                .then(json => {
                    if (!json.validity) logout()
                    else {
                        setIsSuperAdmin(json.user.role === "superadmin")
                        setLoginCheck(true)
                    }
                })
        }
        else {
            logout()
        }
    }, [page])

    const synchronize = () => {
        fetch(`${process.env.REACT_APP_API_URL || 'http://localhost:4000'}/api/edit-item/sync`, {
            headers: {
                'Authorization': 'JWT ' + sessionStorage.getItem('token')
            }
        })
        .then((res) => res.json())
        .then(json => {
            if (json.status === 200) {
                console.log(json.message)
                dispatch({
                    type: "ADD_NOTIFICATION",
                    payload: {
                        id: v4(),
                        type: "SUCCESS",
                        message: "Données synchronisées"
                    }
                })
            }
            else {
                console.log(json.error)
                dispatch({
                    type: "ADD_NOTIFICATION",
                    payload: {
                        id: v4(),
                        type: "ERROR",
                        message: "Un problème est survenu"
                    }
                })
            }
        })
    };

    return (
        loginCheck &&
        <div id="admin-panel">
            <div className="sidebar">
                <a href="/" target="_blank">
                    <img src='/img/logo_text-light-xl.png' alt='' />
                </a>

                <div className="nav-buttons">
                    <button className={page === "accueil" ? "selected" : ""} onClick={() => setPage("accueil")}>Accueil</button>
                    <button className={page === "chalet-bois" ? "selected" : ""} onClick={() => setPage("chalet-bois")}>Chalet en Bois</button>
                    <button className={page === "chalet-rondin" ? "selected" : ""} onClick={() => setPage("chalet-rondin")}>Chalet en Rondin</button>
                    <button className={page === "mobile-home" ? "selected" : ""} onClick={() => setPage("mobile-home")}>Mobile-Home</button>
                    <button className={page === "ecolodges" ? "selected" : ""} onClick={() => setPage("ecolodges")}>Ecolodges</button>
                    <button className={page === "emplacements" ? "selected" : ""} onClick={() => setPage("emplacements")}>Emplacements</button>
                    <button className={page === "restaurant" ? "selected" : ""} onClick={() => setPage("restaurant")}>Restaurant</button>
                    <button className={page === "activites" ? "selected" : ""} onClick={() => setPage("activites")}>Activités</button>
                    <button className={page === "tarifs" ? "selected" : ""} onClick={() => setPage("tarifs")}>Tarifs</button>
                    <button className={page === "divers" ? "selected" : ""} onClick={() => setPage("divers")}>Divers</button>
                    {
                        isSuperAdmin &&
                        <button className={page === "comptes" ? "selected" : ""} onClick={() => setPage("comptes")}>Comptes</button>
                    }
                </div>

                {
                    isSuperAdmin &&
                    <button className="logout-button" type='button' onClick={() => synchronize()}>Synchronisation</button>
                }
                <button className="logout-button" type='button' onClick={() => logout()}>Déconnexion</button>
            </div>

            <div className="main">
                {
                    (page !== "" && page !== "comptes") &&
                    <EditPage page={page} />
                }
                {
                    (page === "comptes" && isSuperAdmin) &&
                        <AccountMgt />
                }
            </div>
        </div>
    )

}

export default AdminPanel;