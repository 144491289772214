import React, {
    useState,
    useEffect
} from 'react';
import {
    Link
} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import './Navbar.css';
import Dropdown from '../Dropdown/Dropdown';

function Navbar({ path }) {

    const [isTop, setIsTop] = useState(true);
    const [open, setOpen] = useState(false);
    const [homepage, setHomepage] = useState(true);

    const { t } = useTranslation('navbar-footer');

    useEffect(() => {
        if ((path === "/" || path === "/activites" || path === "/infos") && homepage === false) {
            setHomepage(true);
        }
        else if (path !== "/" && path !== "/activites" && path !== "/infos" && homepage === true) {
            setHomepage(false);
        }
        
        document.addEventListener('scroll', () => {
            const currentIsTop = window.scrollY < 20;
            if (currentIsTop !== isTop) {
                setIsTop(currentIsTop);
            }
        });
    });

    return (
        <div className={'navbar' + (homepage ? ' homepage' : '') + (isTop ? ' top' : '') + (open ? ' open' : '')}>
            <Link to="/" onClick={(open ? () => setOpen(false) : null)}>{t('tabs.home')}</Link>
            <Dropdown mainNavOpen={open} setMainNavOpen={setOpen}/>
            <Link to="/restaurant" onClick={(open ? () => setOpen(false) : null)}>{t('tabs.restaurant')}</Link>
            <Link className='logo' to="/" onClick={(open ? () => setOpen(false) : null)}></Link>
            <Link to="/activites" onClick={(open ? () => setOpen(false) : null)}>{t('tabs.activities')}</Link>
            <Link to="/reserver" onClick={(open ? () => setOpen(false) : null)}>{t('tabs.booking')}</Link>
            <Link to="/infos" onClick={(open ? () => setOpen(false) : null)}>{t('tabs.infos')}</Link>
            <span className="icon" onClick={() => setOpen(!open)}>{(open ? '\u26cc' : '\u2630')}</span>
        </div>
    )
}

export default Navbar;