import React, { useState, useEffect, useContext } from 'react';
import './SeasonDateItem.css';
import { NotificationContext } from '../NotificationProvider/NotificationProvider';
import { v4 } from "uuid";
import { DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import format from "date-fns/format";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

function SeasonDateItem({ id, title, file, jsonKey }) {

    const [ seasonStart, setSeasonStart ] = useState(null)
    const [ seasonEnd, setSeasonEnd ] = useState(null)
    const [ highSeasonStart, setHighSeasonStart ] = useState(null)
    const [ highSeasonEnd, setHighSeasonEnd ] = useState(null)

    const [ registering, setRegistering ] = useState(false)

    const dispatch = useContext(NotificationContext)

    class LocalizedUtils extends DateFnsUtils {
        getDatePickerHeaderText(date) {
            return format(date, "d MMM yyyy", { locale: this.locale });
        }
    }

    const materialTheme = createMuiTheme({
        palette: {
            primary: {
                light: '#0197ab',
                main: '#0197ab',
                dark: '#0197ab',
                contrastText: '#fff'
            },
            secondary: {
                light: '#0197ab',
                main: '#0197ab',
                dark: '#0197ab',
                contrastText: '#fff'
            }
        }
    });

    useEffect(() => {

        fetch(`${process.env.REACT_APP_API_URL || 'http://localhost:4000'}/api/season-date-item?id=${id}`, {
            headers: {
                'Authorization': 'JWT ' + sessionStorage.getItem('token')
            }
        })
        .then((res) => res.json())
        .then(json => {
            if (json.status === 200) {

                setSeasonStart(json.item.all.start);
                setSeasonEnd(json.item.all.end);
                setHighSeasonStart(json.item.high.start);
                setHighSeasonEnd(json.item.high.end);
            }
        })
    }, [id, title]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setRegistering(true);

        fetch(`${process.env.REACT_APP_API_URL || 'http://localhost:4000'}/api/season-date-item/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'JWT ' + sessionStorage.getItem('token')
            },
            body: `id=${id}&file=${file}&key=${jsonKey}&seasonStart=${encodeURI(seasonStart)}&seasonEnd=${encodeURI(seasonEnd)}&highSeasonStart=${encodeURI(highSeasonStart)}&highSeasonEnd=${encodeURI(highSeasonEnd)}`
        })
        .then((res) => res.json())
        .then(json => {
            if (json.status === 200) {
                console.log(json.message)
                dispatch({
                    type: "ADD_NOTIFICATION",
                    payload: {
                        id: v4(),
                        type: "SUCCESS",
                        message: `${title} mis à jour`
                    }
                })
            }
            else {
                console.log(json.error)
                dispatch({
                    type: "ADD_NOTIFICATION",
                    payload: {
                        id: v4(),
                        type: "ERROR",
                        message: "Un problème est survenu"
                    }
                })
            }
            setRegistering(false);
        })
        .catch((err) => {
            dispatch({
                type: "ADD_NOTIFICATION",
                payload: {
                    id: v4(),
                    type: "ERROR",
                    message: "Un problème est survenu"
                }
            })
            setRegistering(false);
        })
    }

    return (
        <ThemeProvider theme={materialTheme}>
            <div className="season-date-item">
                <h2 className="title">{ title }</h2>

                <p>Saison Complète</p>

                <div className="form">
                    <div>
                        <label htmlFor={`season-start-${id}`}>Début</label>
                        <DatePicker 
                            format={"d MMM yyyy"} 
                            autoOk={true}
                            cancelLabel={"Annuler"}
                            okLabel={""}
                            minDateMessage={"La date ne doit pas être antérieure à la date minimale"}
                            maxDateMessage={"La date ne doit pas être supérieure à la date maximale"}
                            value={seasonStart} 
                            onChange={setSeasonStart} 
                        />
                    </div>
                    <div>
                        <label htmlFor={`season-end-${id}`}>Fin</label>
                        <DatePicker 
                            format={"d MMM yyyy"} 
                            autoOk={true}
                            cancelLabel={"Annuler"}
                            okLabel={""}
                            minDateMessage={"La date ne doit pas être antérieure à la date minimale"}
                            maxDateMessage={"La date ne doit pas être supérieure à la date maximale"}
                            minDate={seasonStart}
                            value={seasonEnd} 
                            onChange={setSeasonEnd} 
                        />
                    </div>
                </div>

                <p>Haute-Saison</p>
                
                <div className="form">
                    <div>
                        <label htmlFor={`high-season-start-${id}`}>Début</label>
                        <DatePicker 
                            format={"d MMM yyyy"} 
                            autoOk={true}
                            cancelLabel={"Annuler"}
                            okLabel={""}
                            minDateMessage={"La date ne doit pas être antérieure à la date minimale"}
                            maxDateMessage={"La date ne doit pas être supérieure à la date maximale"}
                            minDate={seasonStart}
                            maxDate={seasonEnd}
                            value={highSeasonStart} 
                            onChange={setHighSeasonStart} 
                        />
                    </div>
                    <div>
                        <label htmlFor={`high-season-end-${id}`}>Fin</label>
                        <DatePicker 
                            format={"d MMM yyyy"} 
                            autoOk={true}
                            cancelLabel={"Annuler"}
                            okLabel={""}
                            minDateMessage={"La date ne doit pas être antérieure à la date minimale"}
                            maxDateMessage={"La date ne doit pas être supérieure à la date maximale"}
                            minDate={highSeasonStart}
                            maxDate={seasonEnd}
                            value={highSeasonEnd} 
                            onChange={setHighSeasonEnd} 
                        />
                    </div>
                </div>

                <button onClick={handleSubmit}>{registering ? <i className="lni lni-spinner spinner-icon"></i> : "Enregistrer"}</button>
            </div>
        </ThemeProvider>
    )

}

export default SeasonDateItem;