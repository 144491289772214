import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Gallery.css';
import Slider from "react-slick";
import Slideshow from 'react-slidez';

function toggleFullscreen(setSlideIndex, setFullscreen, index, fullscreenState) {
    setSlideIndex(index);
    setFullscreen(!fullscreenState);
}

function Gallery({ photos, color }) {

    const [fullscreen, setFullscreen] = useState(false);
    const [slideIndex, setSlideIndex] = useState(0);

    const { t } = useTranslation('gallery');

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: (photos.length >= 3 ? 3 : photos.length),
        slidesToScroll: 1,
        lazyLoad: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: (photos.length >= 2 ? 2 : photos.length)
                }
            },
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    return (
        <div className='gallery'>
            <h2 className={'title ' + color}>{t('title')}</h2>
            <Slider {...settings}>
                {photos.map((photo, i) => {
                    return (
                        <div key={i} onClick={() => toggleFullscreen(setSlideIndex, setFullscreen, i, fullscreen)}>
                            <img src={photo.img} alt={photo.alt} />
                        </div>
                    )
                })}
            </Slider>

            { fullscreen &&
            <div>
                <Slideshow 
                    showIndex
                    showArrows
                    autoplay={false}
                    enableKeyboard
                    useDotIndex
                    slideInterval={2000}
                    defaultIndex={slideIndex}
                    slides={photos.map(photo => photo.img)}
                    effect={'fade'}
                    height={'100%'}
                    width={'100%'}
                />
                <span className='close-button' onClick={() => toggleFullscreen(setSlideIndex, setFullscreen, 0, fullscreen)}></span>
            </div>}
        </div>
    )
}

export default Gallery;