import React, { useState, useEffect, useContext } from 'react';
import './PdfItem.css';
import { NotificationContext } from '../NotificationProvider/NotificationProvider';
import { v4 } from "uuid";
import axios from "axios";

function PdfItem({ id, title, file, jsonKey }) {

    const dispatch = useContext(NotificationContext)

    const [pdf, setPdf] = useState(null)
    const [pdfPreview, setPdfPreview] = useState("")

    const [registering, setRegistering] = useState(false)

    useEffect(() => {

        fetch(`${process.env.REACT_APP_API_URL || 'http://localhost:4000'}/api/pdf-item?id=${id}`, {
            headers: {
                'Authorization': 'JWT ' + sessionStorage.getItem('token')
            }
        })
            .then((res) => res.json())
            .then(json => {
                if (json.status === 200) {
                    setPdfPreview(json.item.pdf)
                }
            })
    }, [id, title]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setRegistering(true);

        const data = new FormData()
        data.append("id", id)
        data.append("file", file)
        data.append("key", jsonKey)
        data.append("pdf", pdf)

        axios.post(`${process.env.REACT_APP_API_URL || 'http://localhost:4000'}/api/pdf-item/update`, data, {
            headers: {
                'Authorization': 'JWT ' + sessionStorage.getItem('token')
            }
        })
            .then((res) => res.data)
            .then(json => {
                console.log(json);
                if (json.status === 200) {
                    console.log(json.message)
                    dispatch({
                        type: "ADD_NOTIFICATION",
                        payload: {
                            id: v4(),
                            type: "SUCCESS",
                            message: `${title} mis à jour`
                        }
                    })
                }
                else {
                    console.log(json.error)
                    dispatch({
                        type: "ADD_NOTIFICATION",
                        payload: {
                            id: v4(),
                            type: "ERROR",
                            message: "Un problème est survenu"
                        }
                    })
                }
                setRegistering(false);
            })
            .catch((err) => {
                dispatch({
                    type: "ADD_NOTIFICATION",
                    payload: {
                        id: v4(),
                        type: "ERROR",
                        message: "Un problème est survenu"
                    }
                })
                console.log(err)
                setRegistering(false);
            })
    }

    const onChangeHandler = (event) => {
        setPdf(event.target.files[0])
        setPdfPreview(URL.createObjectURL(event.target.files[0]))
    }

    return (
        <div className="pdf-item">
            <h2 className="title">{title}</h2>

            <div className="form">
                <label htmlFor={`input-${id}`}>PDF</label>
                <label htmlFor={`input-${id}`}>Choisir un fichier <i className="lni lni-add-files"></i></label>
                <input id={`input-${id}`} type="file" accept=".pdf" onChange={onChangeHandler} hidden></input>
            </div>

            <div className="preview">
                <iframe src={pdfPreview}></iframe>
            </div>

            <button className="save-button" onClick={handleSubmit}>{registering ? <i className="lni lni-spinner spinner-icon"></i> : "Enregistrer"}</button>
        </div>
    )

}

export default PdfItem;