import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTranslation } from 'react-i18next';
import { getHtmlString } from '../../utils/parseHtmlString';
import CampingContent from '../CampingContent/CampingContent';
import BookingRibbon from '../BookingRibbon/BookingRibbon';
import Gallery from '../Gallery/Gallery';
import { ImageContext } from '../ImageProvider/ImageProvider';

function Camping() {

    let { type } = useParams();
    let color, title, elements;

    const { t } = useTranslation('camping');

    const images = useContext(ImageContext);

    switch (type) {
        case 'chalet-bois':
            color = 'blue';
            title = t('chalet-bois.title');
            elements = [
                {
                    leftSide: <div className='image'>
                        <LazyLoadImage src={images.chalet_bois.main.img} alt={images.chalet_bois.main.alt} />
                    </div>,
                    rightSide: <div className='description'>
                        <p className='subtitle'>{t('chalet-bois.content.description.title')}</p>

                        <div dangerouslySetInnerHTML={getHtmlString(t('chalet-bois.content.description.text'))}></div>

                        <a target='_blank' rel="noopener noreferrer" href={t('chalet-bois.content.inventory')} className='inventory-link'><i className="lni lni-clipboard"></i> {t('miscellaneous.inventory-link')}</a>

                        <p className='subtitle'>{t('miscellaneous.prices-subtitle') + ' ' + new Date().getFullYear() }</p>

                        <div dangerouslySetInnerHTML={getHtmlString(t('chalet-bois.content.prices') + t('miscellaneous.fee') + t('miscellaneous.accomodation-extras'))}></div>

                        <p className='subtitle'>{t('facilities.title')}</p>

                        <div dangerouslySetInnerHTML={getHtmlString(t('facilities.text'))}></div>
                    </div>,
                    photos: images.chalet_bois.galleries.chalet_bois
                }
            ];
            break;
        case 'chalet-rondins':
            color = 'red';
            title = t('chalet-rondin.title');
            elements = [
                {
                    leftSide: <div className='description'>
                        <p className='subtitle'>{t('chalet-rondin.content.description.title')}</p>

                        <div dangerouslySetInnerHTML={getHtmlString(t('chalet-rondin.content.description.text'))}></div>

                        <a target='_blank' rel="noopener noreferrer" href={t('chalet-rondin.content.inventory')} className='inventory-link'><i className="lni lni-clipboard"></i> {t('miscellaneous.inventory-link')}</a>

                        <p className='subtitle'>{t('miscellaneous.prices-subtitle') + ' ' + new Date().getFullYear() }</p>

                        <div dangerouslySetInnerHTML={getHtmlString(t('chalet-rondin.content.prices') + t('miscellaneous.fee') + t('miscellaneous.accomodation-extras'))}></div>

                        <p className='subtitle'>{t('facilities.title')}</p>

                        <div dangerouslySetInnerHTML={getHtmlString(t('facilities.text'))}></div>
                    </div>,
                    rightSide: <div className='image'>
                        <LazyLoadImage src={images.chalet_rondin.main.img} alt={images.chalet_rondin.main.alt} />
                    </div>,
                    photos: images.chalet_rondin.galleries.chalet_rondin
                }
            ];
            break;
        case 'mobile-home':
            color = 'green';
            title = t('mobile-home.title');
            elements = [
                {
                    leftSide: <div className='image'>
                        <LazyLoadImage src={images.mobile_home.main.img} alt={images.mobile_home.main.alt} />
                    </div>,
                    rightSide: <div className='description'>
                        <p className='subtitle'>{t('mobile-home.content.description.title')}</p>

                        <div dangerouslySetInnerHTML={getHtmlString(t('mobile-home.content.description.text'))}></div>

                        <a target='_blank' rel="noopener noreferrer" href={t('mobile-home.content.inventory')} className='inventory-link'><i className="lni lni-clipboard"></i> {t('miscellaneous.inventory-link')}</a>

                        <p className='subtitle'>{t('miscellaneous.prices-subtitle') + ' ' + new Date().getFullYear() }</p>

                        <div dangerouslySetInnerHTML={getHtmlString(t('mobile-home.content.prices') + t('miscellaneous.fee') + t('miscellaneous.accomodation-extras'))}></div>

                        <p className='subtitle'>{t('facilities.title')}</p>

                        <div dangerouslySetInnerHTML={getHtmlString(t('facilities.text'))}></div>
                    </div>,
                    photos: images.mobile_home.galleries.mobile_home
                }
            ]
            break;
        case 'ecolodges':
            color = 'blue';
            title = t('ecolodge.title');
            elements = [
                {
                    leftSide: <div className='description'>
                        <p className='subtitle'>{t('ecolodge.content.bivouac.description.title')}</p>

                        <div dangerouslySetInnerHTML={getHtmlString(t('ecolodge.content.bivouac.description.text'))}></div>

                        <a target='_blank' rel="noopener noreferrer" href={t('ecolodge.content.bivouac.inventory')} className='inventory-link'><i className="lni lni-clipboard"></i> {t('miscellaneous.inventory-link')}</a>

                        <p className='subtitle'>{t('miscellaneous.prices-subtitle') + ' ' + new Date().getFullYear() }</p>

                        <div dangerouslySetInnerHTML={getHtmlString(t('ecolodge.content.bivouac.prices') + t('miscellaneous.fee') + t('miscellaneous.accomodation-extras'))}></div>
                    </div>,
                    rightSide: <div className='image'>
                        <LazyLoadImage src={images.ecolodge.main.bivouac.img} alt={images.ecolodge.main.bivouac.alt} />
                    </div>,
                    photos: images.ecolodge.galleries.bivouac
                },
                {
                    leftSide: <div className='image'>
                        <LazyLoadImage src={images.ecolodge.main.calin.img} alt={images.ecolodge.main.calin.alt} />
                    </div>,
                    rightSide: <div className='description'>
                        <p className='subtitle'>{t('ecolodge.content.calin.description.title')}</p>

                        <div dangerouslySetInnerHTML={getHtmlString(t('ecolodge.content.calin.description.text'))}></div>

                        <a target='_blank' rel="noopener noreferrer" href={t('ecolodge.content.calin.inventory')} className='inventory-link'><i className="lni lni-clipboard"></i> {t('miscellaneous.inventory-link')}</a>

                        <p className='subtitle'>{t('miscellaneous.prices-subtitle') + ' ' + new Date().getFullYear() }</p>

                        <div dangerouslySetInnerHTML={getHtmlString(t('ecolodge.content.calin.prices') + t('miscellaneous.fee') + t('miscellaneous.accomodation-extras'))}></div>
                    </div>,
                    photos: images.ecolodge.galleries.calin
                },
                {
                    leftSide: <div className='description'>
                        <p className='subtitle'>{t('ecolodge.content.amazone.description.title')}</p>

                        <div dangerouslySetInnerHTML={getHtmlString(t('ecolodge.content.amazone.description.text'))}></div>

                        <a target='_blank' rel="noopener noreferrer" href={t('ecolodge.content.amazone.inventory')} className='inventory-link'><i className="lni lni-clipboard"></i> {t('miscellaneous.inventory-link')}</a>

                        <p className='subtitle'>{t('miscellaneous.prices-subtitle') + ' ' + new Date().getFullYear() }</p>

                        <div dangerouslySetInnerHTML={getHtmlString(t('ecolodge.content.amazone.prices') + t('miscellaneous.fee') + t('miscellaneous.accomodation-extras'))}></div>
                    </div>,
                    rightSide: <div className='image'>
                        <LazyLoadImage src={images.ecolodge.main.amazone.img} alt={images.ecolodge.main.amazone.alt} />
                    </div>,
                    photos: images.ecolodge.galleries.amazone
                },
                {
                    leftSide: <div className='image'>
                        <LazyLoadImage src={images.ecolodge.main.luxe.img} alt={images.ecolodge.main.luxe.alt} />
                    </div>,
                    rightSide: <div className='description'>
                        <p className='subtitle'>{t('ecolodge.content.luxe.description.title')}</p>

                        <div dangerouslySetInnerHTML={getHtmlString(t('ecolodge.content.luxe.description.text'))}></div>

                        <a target='_blank' rel="noopener noreferrer" href={t('ecolodge.content.luxe.inventory')} className='inventory-link'><i className="lni lni-clipboard"></i> {t('miscellaneous.inventory-link')}</a>

                        <p className='subtitle'>{t('miscellaneous.prices-subtitle') + ' ' + new Date().getFullYear() }</p>

                        <div dangerouslySetInnerHTML={getHtmlString(t('ecolodge.content.luxe.prices') + t('miscellaneous.fee') + t('miscellaneous.accomodation-extras'))}></div>
                    </div>,
                    photos: images.ecolodge.galleries.luxe
                },
                {
                    leftSide: <div className='description'>
                        <p className='subtitle'>{t('ecolodge.content.nid.description.title')}</p>

                        <div dangerouslySetInnerHTML={getHtmlString(t('ecolodge.content.nid.description.text'))}></div>

                        <a target='_blank' rel="noopener noreferrer" href={t('ecolodge.content.nid.inventory')} className='inventory-link'><i className="lni lni-clipboard"></i> {t('miscellaneous.inventory-link')}</a>

                        <p className='subtitle'>{t('miscellaneous.prices-subtitle') + ' ' + new Date().getFullYear() }</p>

                        <div dangerouslySetInnerHTML={getHtmlString(t('ecolodge.content.nid.prices') + t('miscellaneous.fee') + t('miscellaneous.accomodation-extras'))}></div>
                    </div>,
                    rightSide: <div className='image'>
                        <LazyLoadImage src={images.ecolodge.main.nid.img} alt={images.ecolodge.main.nid.alt} />
                    </div>,
                    photos: images.ecolodge.galleries.nid
                }
            ]

            break;
        case 'emplacements':
            color = 'red';
            title = t('emplacement.title');
            elements = [
                {
                    leftSide: <div className='image'>
                        <LazyLoadImage src={images.emplacement.main.img} alt={images.emplacement.main.alt} />
                    </div>,
                    rightSide: <div className='description'>
                        <p className='subtitle'>{t('emplacement.content.description.title')}</p>

                        <div dangerouslySetInnerHTML={getHtmlString(t('emplacement.content.description.text'))}></div>

                        <p className='subtitle'>{t('emplacement.content.services.title')}</p>

                        <div dangerouslySetInnerHTML={getHtmlString(t('emplacement.content.services.text'))}></div>

                        <p className='subtitle'>{t('miscellaneous.prices-subtitle') + ' ' + new Date().getFullYear() }</p>

                        <div dangerouslySetInnerHTML={getHtmlString(t('emplacement.content.prices') + t('miscellaneous.fee') + t('miscellaneous.low-season-discount'))}></div>
                    </div>,
                    photos: images.emplacement.galleries.emplacement
                }
            ]
            break;
        default:
            color = '';
    }

    return (
        <div id='camping-item' className='container'>
            <div className={'main-content ' + color}>
                <h3 className='title'>{title}</h3>

                {elements.map((element, i) => {
                    return (
                        <div key={i}>
                            <CampingContent leftSide={element.leftSide} rightSide={element.rightSide} />

                            <Gallery photos={element.photos} color={color} />
                        </div>
                    );
                })}
            </div>

            <BookingRibbon color={color} />
        </div>
    )
}

export default Camping;