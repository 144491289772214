import React, { useState, useEffect, useContext } from 'react';
import './MiscPricesItem.css';
import { NotificationContext } from '../NotificationProvider/NotificationProvider';
import { v4 } from "uuid";

function MiscPricesItem({ id, title, file, jsonKey }) {

    const [ tax, setTax ] = useState("")
    const [ cleaningFeeStandard, setCleaningFeeStandard ] = useState("")
    const [ cleaningFeeDog, setCleaningFeeDog ] = useState("")
    const [ sheetsSimple, setSheetsSimple ] = useState("")
    const [ sheetsDouble, setSheetsDouble ] = useState("")

    const [ registering, setRegistering ] = useState(false)

    const dispatch = useContext(NotificationContext)

    useEffect(() => {

        fetch(`${process.env.REACT_APP_API_URL || 'http://localhost:4000'}/api/camping-prices-item/misc?id=${id}`, {
            headers: {
                'Authorization': 'JWT ' + sessionStorage.getItem('token')
            }
        })
        .then((res) => res.json())
        .then(json => {
            if (json.status === 200) {

                json.item.tax = Number.isInteger(json.item.tax) ? json.item.tax : json.item.tax.toFixed(2).replace('.', ',')
                json.item.cleaningFee.normal = Number.isInteger(json.item.cleaningFee.normal) ? json.item.cleaningFee.normal : json.item.cleaningFee.normal.toFixed(2).replace('.', ',')
                json.item.cleaningFee.dog = Number.isInteger(json.item.cleaningFee.dog) ? json.item.cleaningFee.dog : json.item.cleaningFee.dog.toFixed(2).replace('.', ',')
                json.item.sheets.simple = Number.isInteger(json.item.sheets.simple) ? json.item.sheets.simple : json.item.sheets.simple.toFixed(2).replace('.', ',')
                json.item.sheets.double = Number.isInteger(json.item.sheets.double) ? json.item.sheets.double : json.item.sheets.double.toFixed(2).replace('.', ',')

                setTax(json.item.tax === 0 ? "" : json.item.tax)
                setCleaningFeeStandard(json.item.cleaningFee.normal === 0 ? "" : json.item.cleaningFee.normal)
                setCleaningFeeDog(json.item.cleaningFee.dog === 0 ? "" : json.item.cleaningFee.dog)
                setSheetsSimple(json.item.sheets.simple === 0 ? "" : json.item.sheets.simple)
                setSheetsDouble(json.item.sheets.double === 0 ? "" : json.item.sheets.double)
            }
        })
    }, [id, title]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setRegistering(true);

        fetch(`${process.env.REACT_APP_API_URL || 'http://localhost:4000'}/api/camping-prices-item/update-misc-prices`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'JWT ' + sessionStorage.getItem('token')
            },
            body: `id=${id}&file=${file}&key=${jsonKey}&tax=${encodeURI(tax)}&cleaningFeeStandard=${encodeURI(cleaningFeeStandard)}&cleaningFeeDog=${encodeURI(cleaningFeeDog)}&sheetsSimple=${encodeURI(sheetsSimple)}&sheetsDouble=${encodeURI(sheetsDouble)}`
        })
        .then((res) => res.json())
        .then(json => {
            if (json.status === 200) {
                console.log(json.message)
                dispatch({
                    type: "ADD_NOTIFICATION",
                    payload: {
                        id: v4(),
                        type: "SUCCESS",
                        message: `${title} mis à jour`
                    }
                })
            }
            else {
                console.log(json.error)
                dispatch({
                    type: "ADD_NOTIFICATION",
                    payload: {
                        id: v4(),
                        type: "ERROR",
                        message: "Un problème est survenu"
                    }
                })
            }
            setRegistering(false);
        })
        .catch((err) => {
            dispatch({
                type: "ADD_NOTIFICATION",
                payload: {
                    id: v4(),
                    type: "ERROR",
                    message: "Un problème est survenu"
                }
            })
            setRegistering(false);
        })
    }

    return (
        <div className="misc-prices-item">
            <h2 className="title">{ title }</h2>

            <div className="form">
                <div>
                    <label htmlFor={`tax-${id}`}>Taxe de séjour</label>
                    <input id={`tax-${id}`} type="text" value={tax} onChange={(event) => setTax(event.target.value)}></input>
                </div>
            </div>

            <p>Ménage</p>

            <div className="form">
                <div>
                    <label htmlFor={`cleaning-fee-standard-${id}`}>Standard</label>
                    <input id={`cleaning-fee-standard-${id}`} type="text" value={cleaningFeeStandard} onChange={(event) => setCleaningFeeStandard(event.target.value)}></input>
                </div>
                <div>
                    <label htmlFor={`cleaning-fee-dog-${id}`}>Avec Chien</label>
                    <input id={`cleaning-fee-dog-${id}`} type="text" value={cleaningFeeDog} onChange={(event) => setCleaningFeeDog(event.target.value)}></input>
                </div>
            </div>

            <p>Draps</p>

            <div className="form">
                <div>
                    <label htmlFor={`sheets-simple-${id}`}>Simple</label>
                    <input id={`sheets-simple-${id}`} type="text" value={sheetsSimple} onChange={(event) => setSheetsSimple(event.target.value)}></input>
                </div>
                <div>
                    <label htmlFor={`sheets-double-${id}`}>Double</label>
                    <input id={`sheets-double-${id}`} type="text" value={sheetsDouble} onChange={(event) => setSheetsDouble(event.target.value)}></input>
                </div>
            </div>

            <button onClick={handleSubmit}>{registering ? <i className="lni lni-spinner spinner-icon"></i> : "Enregistrer"}</button>
        </div>
    )

}

export default MiscPricesItem;