import React, { useState, useEffect } from 'react';
import './Admin.css';
import AdminPanel from '../AdminPanel/AdminPanel';
import AdminLogin from '../AdminLogin/AdminLogin';
import NotificationProvider from "../NotificationProvider/NotificationProvider";

function Admin() {

    const [logged, setLogged] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (sessionStorage.getItem('token')) {
            fetch(`${process.env.REACT_APP_API_URL || 'http://localhost:4000'}/api/auth/verify`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: encodeURI('token') + "=" + encodeURI(sessionStorage.getItem('token'))
            })
                .then((res) => res.json())
                .then(json => {
                    if (!json.user.reinitPassword) {
                        setLogged(json.validity)
                    }

                    setLoading(false)
                })
        }
        else {
            setLoading(false)
        }
    });

    const setLoggedIn = () => {
        setLogged(true)
    }

    const logout = () => {
        sessionStorage.removeItem('token')
        setLogged(false)
    }

    return (
        <div id="admin">
            <NotificationProvider>
                {loading ? <p>Loading</p> :
                    logged ? <AdminPanel logout={logout} /> : <AdminLogin setLoggedIn={setLoggedIn} />
                }
            </NotificationProvider>
        </div>
    )

}

export default Admin;