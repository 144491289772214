import React from 'react';
import './RestaurantMenu.css';

function RestaurantMenu() {

    return (
        <div id='restaurant-menu' className='container'>

            <h2>Nos Boissons</h2>

            <div>
                <h3>Les Softs</h3>
                <h4 className="top padding-top padding-bottom">Sirops</h4>
                <p className="price top padding-top">2.00€</p>
                <p className="padding-bottom">menthe, pêche, cassis, framboise, grenadine, fraise, citron clair, citron acide, orgeat</p>
                <span></span>

                <h4 className="top padding-top padding-bottom">Limonade</h4>
                <p className="price top padding-top">2.10€</p>
                
                <h4 className="top padding-top padding-bottom">Sodas</h4>
                <p className="price top padding-top">2.60€</p>
                <p className="padding-bottom">orangina, schweppes agrum, nestea pêche, perrier, diabolo, coca, coca zéro</p>
                <span></span>
                
                <h4 className="top padding-top padding-bottom">Jus de fruits</h4>
                <p className="price top padding-top">2.60€</p>
                <p className="padding-bottom">orange, ananas, mangue, pomme, pamplemousse, abricot</p>
                <span></span>
            </div>

            <div>
                <h3>Les Boissons Chaudes</h3>
                <h4 className="top padding-top padding-bottom">Expresso</h4>
                <p className="price top padding-top">1.40€</p>
                
                <h4 className="top padding-top padding-bottom">Double Expresso</h4>
                <p className="price top padding-top">2.60€</p>
                
                <h4 className="top padding-top padding-bottom">Café Noisette</h4>
                <p className="price top padding-top">1.50€</p>
                
                <h4 className="top padding-top padding-bottom">Grand Crème</h4>
                <p className="price top padding-top">2.70€</p>
                
                <h4 className="top padding-top padding-bottom">Café ou Chocolat viennois</h4>
                <p className="price top padding-top">3.50€</p>
                
                <h4 className="top padding-top padding-bottom">Thé, Infusion</h4>
                <p className="price top padding-top">2.30€</p>
                
                <h4 className="top padding-top padding-bottom">Chocolat Chaud</h4>
                <p className="price top padding-top">3.00€</p>
            </div>

            <div>
                <h3>Les Apéritifs</h3>
                <h4 className="top padding-top padding-bottom">Kir Rondin <span>(12cl)</span></h4>
                <p className="price top padding-top">3.50€</p>
                <p className="padding-bottom">sirop d'érable, vin champagnisé</p>
                <span></span>

                <h4 className="top padding-top padding-bottom">Kir Cassis ou Mûre <span>(12cl)</span></h4>
                <p className="price top padding-top">2.60€</p>

                <h4 className="top padding-top padding-bottom">Verre de Vin <span>(12cl)</span></h4>
                <p className="price top padding-top">2.60€</p>

                <h4 className="top padding-top padding-bottom">Ricard, Pastis 51 <span>(2cl)</span></h4>
                <p className="price top padding-top">2.20€</p>

                <h4 className="top padding-top padding-bottom">Martini blanc ou rouge <span>(6cl)</span></h4>
                <p className="price top padding-top">3.10€</p>

                <h4 className="top padding-top padding-bottom">Porto blanc ou rouge <span>(10cl)</span></h4>
                <p className="price top padding-top">3.10€</p>

                <h4 className="top padding-top padding-bottom">Suze <span>(6cl)</span></h4>
                <p className="price top padding-top">3.10€</p>
            </div>

            <div>
                <h3>Les Bières</h3>
                <h4 className="top padding-top padding-bottom">Pelforth Blonde</h4>
                <span className="top"></span>
                <p>bock</p>
                <p className="price">1.50€</p>
                <p>demi</p>
                <p className="price">2.70€</p>
                <p>pinte</p>
                <p className="price">5.00€</p>
                
                <h4 className="top padding-top padding-bottom">Edelweiss Blanche</h4>
                <span className="top"></span>
                <p>bock</p>
                <p className="price">2.00€</p>
                <p>demi</p>
                <p className="price">3.20€</p>
                <p>pinte</p>
                <p className="price">5.80€</p>
                
                <h4 className="top padding-top padding-bottom">Brasserie de la Jonte</h4>
                <p className="price top padding-top">4.50€</p>
                <p className="padding-bottom">33cl, selon disponibilité, merci de vous renseigner auprès du service</p>
                <span></span>
            </div>

            <div>
                <h3>Les Digestifs</h3>

                <h4 className="top padding-top padding-bottom">Verveine Artisanale <span>(2cl)</span></h4>
                <p className="price top padding-top">3.50€</p>

                <h4 className="top padding-top padding-bottom">Poire <span>(2cl)</span></h4>
                <p className="price top padding-top">3.50€</p>

                <h4 className="top padding-top padding-bottom">Cognac <span>(2cl)</span></h4>
                <p className="price top padding-top">3.50€</p>

                <h4 className="top padding-top padding-bottom">Get 27 <span>(4cl)</span></h4>
                <p className="price top padding-top">3.50€</p>

                <h4 className="top padding-top padding-bottom">Don Papa <span>(4cl)</span></h4>
                <p className="price top padding-top">7.00€</p>

                <h4 className="top padding-top padding-bottom">Caol Ila <span>(4cl)</span></h4>
                <p className="price top padding-top">7.00€</p>

                <h4 className="top padding-top padding-bottom">Diplomatico <span>(4cl)</span></h4>
                <p className="price top padding-top">7.00€</p>
            </div>

            <div>
                <h3>Les Alcools</h3>

                <h4 className="top padding-top padding-bottom">Rhum blanc, Malibu, Vodka, Ballantines <span>(4cl)</span></h4>
                <p className="price top padding-top">4.00€</p>
                <p className="padding-bottom">avec coca, jus d'orange, jus d'ananas</p>
                <p className="price padding-bottom">5.00€</p>

                <h4 className="top padding-top padding-bottom">Baileys <span>(4cl)</span></h4>
                <p className="price top padding-top">4.00€</p>

                <h4 className="top padding-top padding-bottom">Jack Daniel's <span>(4cl)</span></h4>
                <p className="price top padding-top">5.00€</p>
            </div>

            <div>
                <h3>Les Eaux</h3>

                <h4 className="top padding-top padding-bottom">Bouteille d'Evian</h4>
                <p className="price top padding-top">4.00€</p>

                <h4 className="top padding-top padding-bottom">Bouteille de Quezac</h4>
                <p className="price top padding-top">4.00€</p>
            </div>

            <h2>Nos Plats</h2>

            <div>
                <h3>Les Ardoises</h3>

                <h4 className="top padding-top padding-bottom">La Fromagère de la Région</h4>
                <p className="price top padding-top">8.90€</p>

                <h4 className="top padding-top padding-bottom">La Charcutière de la Région</h4>
                <p className="price top padding-top">8.90€</p>

                <h4 className="top padding-top padding-bottom">La Mixte Charcutière et Fromagère</h4>
                <p className="price top padding-top">9.90€</p>

                <h4 className="top padding-top padding-bottom">La Mixte Truite et Saumon Fumé</h4>
                <p className="price top padding-top">12.80€</p>

                <h4 className="top padding-top padding-bottom">Le Foie Gras de Canard maison</h4>
                <p className="price top padding-top">12.80€</p>

                <h4 className="top padding-top padding-bottom">La Palette Gourmande</h4>
                <p className="price top padding-top">13.80€</p>
            </div>

            <div>
                <h3>Les Salades Repas</h3>

                <h4 className="top padding-top padding-bottom">La Végétarienne</h4>
                <p className="price top padding-top">13.50€</p>
                <p className="padding-bottom">salade verte, tomate, concombre, oignons rouges, melon, tagliatelles de carotte, légumes grillés</p>
                <span></span>

                <h4 className="top padding-top padding-bottom">La Scandinave</h4>
                <p className="price top padding-top">14.90€</p>
                <p className="padding-bottom">salade verte, tomate, concombre, oignons rouges, duo de truite et saumon fumés, crevettes marinées à la crème citronnée</p>
                <span></span>

                <h4 className="top padding-top padding-bottom">La Lozérienne</h4>
                <p className="price top padding-top">14.90€</p>
                <p className="padding-bottom">salade verte, tomate, concombre, oignons rouges, trilogie de fromage (chèvre, tomme, bleu d'Auvergne), amandes</p>
                <span></span>

                <h4 className="top padding-top padding-bottom">La Gourmande</h4>
                <p className="price top padding-top">15.90€</p>
                <p className="padding-bottom">salade verte, tomate, concombre, oignons rouges, foie gras maison, jambon cru, gésiers confits, amandes</p>
                <span></span>
            </div>

            <div>
                <h3>Les Burgers</h3>

                <h4 className="top padding-top padding-bottom">Le Lozérien</h4>
                <p className="price top padding-top">15.90€</p>
                <p className="padding-bottom">steak du boucher 180gr, tomme, oignons rouges, tomate, sauce à l'ancienne, frites maison, salade</p>
                <span></span>

                <h4 className="top padding-top padding-bottom">Le Végétarien</h4>
                <p className="price top padding-top">14.90€</p>
                <p className="padding-bottom">steak de soja, tomme, ratatouille, oignons rouges, tomate, frites maison, salade</p>
                <span></span>
            </div>

            <div>
                <h3>Les Assiettes</h3>
                <p className="subtitle padding-bottom">servies avec salade verte, une sauce et un accompagnement au choix</p>

                <h4 className="top padding-top padding-bottom">La Planche de faux filet de boeuf</h4>
                <span className="top"></span>
                <p>220gr</p>
                <p className="price">18.50€</p>
                <p>380gr</p>
                <p className="price">24.50€</p>
                
                <h4 className="top padding-top padding-bottom">Le magret de canard</h4>
                <span className="top"></span>
                <p>1/2</p>
                <p className="price">16.90€</p>
                <p>entier</p>
                <p className="price">23.90€</p>

                <h4 className="top padding-top padding-bottom">L'andouillette de Troyes</h4>
                <p className="price top padding-top">15.90€</p>

                <h4 className="top padding-top padding-bottom">La souris d'agneau</h4>
                <p className="price top padding-top">17.90€</p>

                <h4 className="top padding-top padding-bottom">Le filet de truite citronné</h4>
                <p className="price top padding-top">17.50€</p>

                <h4 className="top padding-top padding-bottom">Tartare de boeuf coupé au couteau</h4>
                <p className="price top padding-top">18.50€</p>
                
                <h4 className="top padding-top padding-bottom">Suppléments</h4>
                <span className="top"></span>
                <p>sauce</p>
                <p className="price">1.50€</p>
                <p>accompagnement</p>
                <p className="price">2.70€</p>
            </div>

            <div className="symetric-grid">
                <h4 className="top padding-top padding-bottom">Sauces</h4>

                <h4 className="top padding-top padding-bottom no-border">Accompagnements</h4>

                <p>bleu d'Auvergne,
                <br/>poivre vert,
                <br/>cêpes,
                <br/>citron,
                <br/>fromagère</p>

                <p className="no-border">purée maison,
                <br/>lentilles vertes du Puy cuites au vin rouge,
                <br/>frites maison,
                <br/>ratatouille</p>
            </div>

            <div>
                <h3>Les Fontes</h3>

                <h4 className="top padding-top padding-bottom">Le Parmentier d'aligot</h4>
                <p className="price top padding-top">15.90€</p>
                <p className="padding-bottom">aligot au confis de canard, salade verte</p>
                <span></span>

                <h4 className="top padding-top padding-bottom">La Palhères des Bois</h4>
                <p className="price top padding-top">14.90€</p>
                <p className="padding-bottom">pomme de terre farcie sur lit de cêpes et crème fraiche, salade verte</p>
                <span></span>
            </div>

            <div>
                <h3>Les Fondues</h3>
                <p className="subtitle padding-bottom">pour 2 personnes minimum (prix par personne)
                <br/>servie avec un assortiment de charcuterie et salade verte</p>

                <h4 className="top padding-top padding-bottom">La traditionnelle</h4>
                <p className="price top padding-top">19.90€</p>
                <p className="padding-bottom">emmental, gruyère, beaufort</p>
                <span></span>

                <h4 className="top padding-top padding-bottom">La forêt de Mercoire</h4>
                <p className="price top padding-top">21.90€</p>
                <p className="padding-bottom">fondue aux cêpes</p>
                <span></span>
            </div>

            <div>
                <h3>Menu Enfant <span>(-10ans)</span></h3>

                <h4 className="top padding-top padding-bottom">Steack haché ou filet de truite</h4>
                <p className="price top padding-top">10.90€</p>
                <p className="padding-bottom">avec frites et glace en dessert</p>
                <span></span>
            </div>

            <h2>Nos Pizzas</h2>

            <div>
                <h3>Base tomate</h3>

                <h4 className="top padding-top padding-bottom">Fromage</h4>
                <p className="price top padding-top">9.90€</p>
                <p className="padding-bottom">tomate, fromage, olives</p>
                <span></span>

                <h4 className="top padding-top padding-bottom">Forestière</h4>
                <p className="price top padding-top">10.20€</p>
                <p className="padding-bottom">tomate, fromage, champignons, olives</p>
                <span></span>

                <h4 className="top padding-top padding-bottom">Margarita</h4>
                <p className="price top padding-top">10.20€</p>
                <p className="padding-bottom">tomate, fromage, jambon, olives</p>
                <span></span>

                <h4 className="top padding-top padding-bottom">Royale</h4>
                <p className="price top padding-top">10.20€</p>
                <p className="padding-bottom">tomate, fromage, jambon, champignons, olives</p>
                <span></span>

                <h4 className="top padding-top padding-bottom">Toscane</h4>
                <p className="price top padding-top">10.20€</p>
                <p className="padding-bottom">tomate, fromage, anchois, câpres, oignons, olives</p>
                <span></span>

                <h4 className="top padding-top padding-bottom">Rose des vents</h4>
                <p className="price top padding-top">11.20€</p>
                <p className="padding-bottom">tomate, lardons, champignons, oignons, crème fraiche, olives</p>
                <span></span>

                <h4 className="top padding-top padding-bottom">Auvergnate</h4>
                <p className="price top padding-top">11.20€</p>
                <p className="padding-bottom">tomate, fromage, bleu d'Auvergne, lardons, crème fraiche, olives</p>
                <span></span>

                <h4 className="top padding-top padding-bottom">Kebab</h4>
                <p className="price top padding-top">11.20€</p>
                <p className="padding-bottom">tomate, fromage, viande kebab, olives</p>
                <span></span>

                <h4 className="top padding-top padding-bottom">Bolognaise</h4>
                <p className="price top padding-top">11.20€</p>
                <p className="padding-bottom">tomate, fromage, viande hachée, crème fraiche, olives</p>
                <span></span>

                <h4 className="top padding-top padding-bottom">4 Fromages</h4>
                <p className="price top padding-top">12.20€</p>
                <p className="padding-bottom">tomate, bleu d'Auvergne, chèvre, reblochon AOC, emmental, olives</p>
                <span></span>
            </div>

            <div>
                <h3>Base crème</h3>

                <h4 className="top padding-top padding-bottom">Blanche</h4>
                <p className="price top padding-top">11.20€</p>
                <p className="padding-bottom">crème fraiche, fromage, chèvre, champignons, olives</p>
                <span></span>

                <h4 className="top padding-top padding-bottom">Savoyarde</h4>
                <p className="price top padding-top">12.20€</p>
                <p className="padding-bottom">crème fraiche, pomme de terre, reblochon AOC, lardons, oignons, olives</p>
                <span></span>

                <h4 className="top padding-top padding-bottom">Raviole</h4>
                <p className="price top padding-top">12.20€</p>
                <p className="padding-bottom">crème fraiche, fromage, lardons, ravioles, olives</p>
                <span></span>

                <h4 className="top padding-top padding-bottom">Nordique</h4>
                <p className="price top padding-top">12.90€</p>
                <p className="padding-bottom">crème fraiche citronnée, fromage, crevettes, truite et saumon fumé, oignons, olives</p>
                <span></span>

                <h4 className="top padding-top padding-bottom">Sud Ouest</h4>
                <p className="price top padding-top">12.90€</p>
                <p className="padding-bottom">crème fraiche, fromage, pomme de terre, magret de canard fumé, oignons, olives</p>
                <span></span>
            </div>

            <h2>Nos Vins</h2>

            <div>
                <h3>Les Blancs <span>(75cl)</span></h3>

                <h4 className="top padding-top padding-bottom">Les Terriers</h4>
                <p className="price top padding-top">20€</p>
                <p className="padding-bottom">IGP Chardonnay Ardèche</p>
                <span></span>

                <h4 className="top padding-top padding-bottom">Only for my friends</h4>
                <p className="price top padding-top">19€</p>
                <p className="padding-bottom">IGP Viognier/Chardonnay Ardèche</p>
                <span></span>

                <h4 className="top padding-top padding-bottom">Loup y es-tu</h4>
                <p className="price top padding-top">22€</p>
                <p className="padding-bottom">Vin de France Pays d'Herault Sauvignon/Muscat</p>
                <span></span>

                <h4 className="top padding-top padding-bottom">La Demoiselle</h4>
                <p className="price top padding-top">24€</p>
                <p className="padding-bottom">IGP gros Manseng Côtes de Gascogne 2017</p>
                <span></span>
            </div>

            <div>
                <h3>Les Rosés <span>(75cl)</span></h3>

                <h4 className="top padding-top padding-bottom">Puech-Haut</h4>
                <p className="price top padding-top">24€</p>
                <p className="padding-bottom">IGP Cinsault/Grenache Hérault</p>
                <span></span>

                <h4 className="top padding-top padding-bottom">Les Pequelous</h4>
                <p className="price top padding-top">20€</p>
                <p className="padding-bottom">IGP Grenache/Gamay Ardèche</p>
                <span></span>

                <h4 className="top padding-top padding-bottom">La Girafe verte</h4>
                <p className="price top padding-top">26€</p>
                <p className="padding-bottom">AOP Cinsault/Syrah/Grenache Côte de Provence</p>
                <span></span>
            </div>

            <div>
                <h3>Les Rouges <span>(75cl)</span></h3>

                <h4 className="top padding-top padding-bottom">Les Lloses</h4>
                <p className="price top padding-top">16€</p>
                <p className="padding-bottom">IGP Pays d'Oc Merlot Vignerons Catalans</p>
                <span></span>

                <h4 className="top padding-top padding-bottom">Meteore</h4>
                <p className="price top padding-top">24€</p>
                <p className="padding-bottom">AOC Faugères, Syrah/Grenache/Carignan</p>
                <span></span>

                <h4 className="top padding-top padding-bottom">Only for my friends</h4>
                <p className="price top padding-top">18€</p>
                <p className="padding-bottom">IGP Syrah/Chatus Ardèche</p>
                <span></span>

                <h4 className="top padding-top padding-bottom">Les Fruités</h4>
                <p className="price top padding-top">19€</p>
                <p className="padding-bottom">IGP Syrah/Grenache Pays de l'Hérault Felines Jourdan</p>
                <span></span>

                <h4 className="top padding-top padding-bottom">Le loup dans la bergerie</h4>
                <p className="price top padding-top">22€</p>
                <p className="padding-bottom">IGP Grenache/Merlot/Syrah Hérault</p>
                <span></span>
            </div>

            <div>
                <h3>Le pichet du rondin</h3>
                <p className="subtitle padding-bottom">vin de table du Vaucluse</p>
            </div>

            <div className="wine-grid">
                <span className="top border-right"></span>
                <p className="top text-center">25cl</p>
                <p className="top text-center">50cl</p>
                <p className="top no-border text-center">75cl</p>

                <h4 className="top padding-top padding-bottom">Blanc / Rosé / Rouge</h4>
                <p className="price top padding-top text-center">4.50€</p>
                <p className="price top padding-top text-center border-left">8.50€</p>
                <p className="price top padding-top text-center border-left">11.90€</p>
            </div>

            <div>
                <p className="subtitle">vin au verre, merci de consulter le service</p>
            </div>
            
        </div>
    )
}

export default RestaurantMenu;