import React from 'react';
import { useTranslation } from 'react-i18next';
import './WelcomeVideo.css';
import BookingWidget from '../BookingWidget/BookingWidget';
import video from './assets/background.mp4';
import { getHtmlString } from '../../utils/parseHtmlString';

function WelcomeVideo(props) {

    const { t } = useTranslation('home');

    return (
        <div className='welcome-section'>
            <div className='video'>
                <video autoPlay muted loop webkit-playsinline playsInline preload='metadata' poster="/img/thumbnail.jpg">
                    <source src={video} type="video/mp4" />
                </video>
            </div>
            <div className='overlay'>
                <div>
                    <h1>{t('landing-screen.title.top')}</h1>
                    <h2>{t('landing-screen.title.bottom')}</h2>
                    <div dangerouslySetInnerHTML={getHtmlString(t('landing-screen.subtitle'))}></div>
                </div>
                <BookingWidget />
            </div>
        </div>
    )
}

export default WelcomeVideo