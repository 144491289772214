import React, { useState, useEffect, useContext } from 'react';
import './TextItem.css';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NotificationContext } from '../NotificationProvider/NotificationProvider';
import { v4 } from "uuid";
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';
import 'flag-icon-css/css/flag-icon.min.css';

function TextItem({ id, title, file, jsonKey }) {

    const [ frContent, setFrContent ] = useState("")
    const [ enContent, setEnContent ] = useState("")

    const [ registering, setRegistering ] = useState(false)

    const dispatch = useContext(NotificationContext)

    const editorConfiguration = {
        language: 'fr',
        toolbar: [ 'heading', '|', 'bold', 'italic', 'link', '|', 'bulletedList', 'numberedList', '|', 'undo', 'redo' ]
    };

    useEffect(() => {

        fetch(`${process.env.REACT_APP_API_URL || 'http://localhost:4000'}/api/text-item?id=${id}`, {
            headers: {
                'Authorization': 'JWT ' + sessionStorage.getItem('token')
            }
        })
        .then((res) => res.json())
        .then(json => {
            if (json.status === 200) {
                setFrContent(json.item.fr)
                setEnContent(json.item.en)
            }
        })
    }, [id, title]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setRegistering(true);

        fetch(`${process.env.REACT_APP_API_URL || 'http://localhost:4000'}/api/text-item/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'JWT ' + sessionStorage.getItem('token')
            },
            body: `id=${id}&file=${file}&key=${jsonKey}&fr=${encodeURI(frContent)}&en=${encodeURI(enContent)}`
        })
        .then((res) => res.json())
        .then(json => {
            if (json.status === 200) {
                console.log(json.message)
                dispatch({
                    type: "ADD_NOTIFICATION",
                    payload: {
                        id: v4(),
                        type: "SUCCESS",
                        message: `${title} mis à jour`
                    }
                })
            }
            else {
                console.log(json.error)
                dispatch({
                    type: "ADD_NOTIFICATION",
                    payload: {
                        id: v4(),
                        type: "ERROR",
                        message: "Un problème est survenu"
                    }
                })
            }
            setRegistering(false);
        })
        .catch((err) => {
            dispatch({
                type: "ADD_NOTIFICATION",
                payload: {
                    id: v4(),
                    type: "ERROR",
                    message: "Un problème est survenu"
                }
            })
            setRegistering(false);
        })
    }

    return (
        <div className="text-item">
            <h2 className="title">{ title }</h2>

            <div className="editor">
                <p><span className="flag-icon flag-icon-fr"></span> Version française</p>
                <CKEditor
                    editor={ ClassicEditor }
                    config={ editorConfiguration }
                    data={ frContent }
                    onInit={ editor => {

                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setFrContent(data)
                    } }
                />
            </div>
            
            <div className="editor">
                <p><span className="flag-icon flag-icon-gb"></span> Version anglaise</p>
                <CKEditor
                    editor={ ClassicEditor }
                    config={ editorConfiguration }
                    data={ enContent }
                    onInit={ editor => {
                        
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setEnContent(data)
                    } }
                />
            </div>

            <button className="save-button" onClick={handleSubmit}>{registering ? <i className="lni lni-spinner spinner-icon"></i> : "Enregistrer"}</button>
        </div>
    )

}

export default TextItem;