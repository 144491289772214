import React, { useState, useEffect, useContext } from 'react';
import './TitleItem.css';
import 'flag-icon-css/css/flag-icon.min.css';
import FancyTitle from '../FancyTitle/FancyTitle';
import MenuEntry from '../MenuEntry/MenuEntry';
import { NotificationContext } from '../NotificationProvider/NotificationProvider';
import { v4 } from "uuid";

function TitleItem({ id, title, file, jsonKey }) {

    const [ firstPartFr, setFirstPartFr ] = useState("")
    const [ secondPartFr, setSecondPartFr ] = useState("")
    const [ firstPartEn, setFirstPartEn ] = useState("")
    const [ secondPartEn, setSecondPartEn ] = useState("")

    const [ registering, setRegistering ] = useState(false)

    const dispatch = useContext(NotificationContext)

    useEffect(() => {

        fetch(`${process.env.REACT_APP_API_URL || 'http://localhost:4000'}/api/title-item?id=${id}`, {
            headers: {
                'Authorization': 'JWT ' + sessionStorage.getItem('token')
            }
        })
        .then((res) => res.json())
        .then(json => {
            if (json.status === 200) {
                setFirstPartFr(json.item.fr.top)
                setSecondPartFr(json.item.fr.bottom)
                setFirstPartEn(json.item.en.top)
                setSecondPartEn(json.item.en.bottom)
            }
        })
    }, [id, title]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setRegistering(true);

        fetch(`${process.env.REACT_APP_API_URL || 'http://localhost:4000'}/api/title-item/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'JWT ' + sessionStorage.getItem('token')
              },
            body: `id=${id}&file=${file}&key=${jsonKey}&frTop=${encodeURI(firstPartFr)}&frBottom=${encodeURI(secondPartFr)}&enTop=${encodeURI(firstPartEn)}&enBottom=${encodeURI(secondPartEn)}`
        })
        .then((res) => res.json())
        .then(json => {
            if (json.status === 200) {
                console.log(json.message)
                dispatch({
                    type: "ADD_NOTIFICATION",
                    payload: {
                        id: v4(),
                        type: "SUCCESS",
                        message: `${title} mis à jour`
                    }
                })
            }
            else {
                console.log(json.error)
                dispatch({
                    type: "ADD_NOTIFICATION",
                    payload: {
                        id: v4(),
                        type: "ERROR",
                        message: "Un problème est survenu"
                    }
                })
            }
            setRegistering(false)
        })
        .catch((err) => {
            dispatch({
                type: "ADD_NOTIFICATION",
                payload: {
                    id: v4(),
                    type: "ERROR",
                    message: "Un problème est survenu"
                }
            })
            setRegistering(false)
        })
    }

    return (
        <div className="title-item">
            <h2 className="title">{ title }</h2>

            <p><span className="flag-icon flag-icon-fr"></span> Version française</p>

            <div className="form">
                <div>
                    <label htmlFor={`title-item-haut-fr-${id}`}>Haut</label>
                    <input id={`title-item-haut-fr-${id}`} type="text" value={firstPartFr} onChange={(event) => setFirstPartFr(event.target.value)}></input>
                    <label htmlFor={`title-item-bas-fr-${id}`}>Bas</label>
                    <input id={`title-item-bas-fr-${id}`} type="text" value={secondPartFr} onChange={(event) => setSecondPartFr(event.target.value)}></input>
                </div>
            </div>

            <div className="preview">
                <h3>Aperçu</h3>
                { file === "restaurant" && jsonKey.startsWith("menu.") 
                    ? <MenuEntry alignment='left' firstText={firstPartFr} secondText={secondPartFr} />
                    : <FancyTitle firstText={firstPartFr} secondText={secondPartFr} color="blue" />
                }
            </div>

            <p><span className="flag-icon flag-icon-gb"></span> Version anglaise</p>

            <div className="form">
                <div>
                    <label htmlFor={`title-item-haut-en-${id}`}>Haut</label>
                    <input id={`title-item-haut-en-${id}`} type="text" value={firstPartEn} onChange={(event) => setFirstPartEn(event.target.value)}></input>
                    <label htmlFor={`title-item-bas-en-${id}`}>Bas</label>
                    <input id={`title-item-bas-en-${id}`} type="text" value={secondPartEn} onChange={(event) => setSecondPartEn(event.target.value)}></input>
                </div>
            </div>

            <div className="preview">
                <h3>Aperçu</h3>
                { file === "restaurant" && jsonKey.startsWith("menu.") 
                    ? <MenuEntry alignment='left' firstText={firstPartEn} secondText={secondPartEn} />
                    : <FancyTitle firstText={firstPartEn} secondText={secondPartEn} color="blue" />
                }
            </div>

            <button onClick={handleSubmit}>{registering ? <i className="lni lni-spinner spinner-icon"></i> : "Enregistrer"}</button>
        </div>
    )

}

export default TitleItem;