import React from 'react';
import { useTranslation } from 'react-i18next';
import './Booking.css';
import FancyTitle from '../FancyTitle/FancyTitle';

function Booking() {

    const { t, i18n } = useTranslation('book');

    return (
        <div className='container booking'>
            <div className='title'>
                <FancyTitle firstText={t("title.top")} secondText={t("title.bottom")} color='red' />
            </div>

            <iframe id="booking-iframe"
                title="Réservation en ligne"
                src={"https://naxiresa.inaxel.com/etape1-criteres.php?compte=rondinbois&lang=" + (i18n.resolvedLanguage === 'fr' ? "1" : "2")}>
            </iframe>
        </div>
    )
}

export default Booking;