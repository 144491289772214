import React from 'react';
import { useTranslation } from 'react-i18next';
import './BookingRibbon.css';
import FancyTitle from '../FancyTitle/FancyTitle';
import LinkButton from '../LinkButton/LinkButton';

function BookingRibbon({ color }) {

    const { t } = useTranslation('camping');

    return (
        <div className='booking-ribbon'>
            <FancyTitle firstText={t('booking-ribbon.title.top')} secondText={t('booking-ribbon.title.bottom')} color={color} />
            <LinkButton link='/reserver' text={t('booking-ribbon.button')} color={color} />
        </div>
    );
}

export default BookingRibbon;
