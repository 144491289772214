import React, { useContext } from 'react';
import {
    Link
} from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './Home.css';
import FancyTitle from '../FancyTitle/FancyTitle';
import WelcomeVideo from '../WelcomeVideo/WelcomeVideo';
import { useTranslation } from 'react-i18next';
import { getHtmlString } from '../../utils/parseHtmlString';
import { ImageContext } from '../ImageProvider/ImageProvider';

function Home() {

    const { t } = useTranslation('home');

    const images = useContext(ImageContext);

    return (
        <div>
            <WelcomeVideo />

            <div id='intro-section'>
                <FancyTitle firstText={t('intro.title.top')} secondText={t('intro.title.bottom')} color='green' />
                <div className="text" dangerouslySetInnerHTML={getHtmlString(t('intro.text'))}></div>
            </div>

            <div id='accomodation-section'>
                <div className='background'></div>

                <div className='grid'>
                    <div className='thumbnail'>
                        <LazyLoadImage src={images.home.chalet_bois.img} alt={images.home.chalet_bois.alt} />
                    </div>
                    <div className='thumbnail-text'>
                        <FancyTitle firstText={t('rentals.chalet-bois.title.top')} secondText={t('rentals.chalet-bois.title.bottom')} color='blue' />
                        <div className="text" dangerouslySetInnerHTML={getHtmlString(t('rentals.chalet-bois.desc'))}></div>
                        <Link to='/camping/chalet-bois'>{t('misc.see-more')} <i className="lni lni-arrow-right"></i></Link>
                    </div>

                    <div className='thumbnail'>
                        <LazyLoadImage src={images.home.chalet_rondin.img} alt={images.home.chalet_rondin.alt} />
                    </div>
                    <div className='thumbnail-text'>
                        <FancyTitle firstText={t('rentals.chalet-rondin.title.top')} secondText={t('rentals.chalet-rondin.title.bottom')} color='red' />
                        <div className="text" dangerouslySetInnerHTML={getHtmlString(t('rentals.chalet-rondin.desc'))}></div>
                        <Link to='/camping/chalet-rondins'>{t('misc.see-more')} <i className="lni lni-arrow-right"></i></Link>
                    </div>

                    <div className='thumbnail'>
                        <LazyLoadImage src={images.home.mobile_home.img} alt={images.home.mobile_home.alt} />
                    </div>
                    <div className='thumbnail-text'>
                        <FancyTitle firstText={t('rentals.mobile-home.title.top')} secondText={t('rentals.mobile-home.title.bottom')} color='green' />
                        <div className="text" dangerouslySetInnerHTML={getHtmlString(t('rentals.mobile-home.desc'))}></div>
                        <Link to='/camping/mobile-home'>{t('misc.see-more')} <i className="lni lni-arrow-right"></i></Link>
                    </div>

                    <div className='thumbnail'>
                        <LazyLoadImage src={images.home.ecolodge.img} alt={images.home.ecolodge.alt} />
                    </div>
                    <div className='thumbnail-text'>
                        <FancyTitle firstText={t('rentals.ecolodge.title.top')}  secondText={t('rentals.ecolodge.title.bottom')}color='blue' />
                        <div style={t('rentals.ecolodge.title.bottom') === "" ? {marginTop: "30px"} : null } className="text" dangerouslySetInnerHTML={getHtmlString(t('rentals.ecolodge.desc'))}></div>
                        <Link to='/camping/ecolodges'>{t('misc.see-more')} <i className="lni lni-arrow-right"></i></Link>
                    </div>

                    <div className='thumbnail'>
                        <LazyLoadImage src={images.home.emplacement.img} alt={images.home.emplacement.alt} />
                    </div>
                    <div className='thumbnail-text'>
                        <FancyTitle firstText={t('rentals.emplacement.title.top')} secondText={t('rentals.emplacement.title.bottom')} color='red' />
                        <div style={t('rentals.ecolodge.title.bottom') === "" ? {marginTop: "30px"} : null } className="text" dangerouslySetInnerHTML={getHtmlString(t('rentals.emplacement.desc'))}></div>
                        <Link to='/camping/emplacements'>{t('misc.see-more')} <i className="lni lni-arrow-right"></i></Link>
                    </div>
                </div>

                <div className='side-title'>
                    <h3>{t('rentals.side-title')}</h3>
                </div>
            </div>

            <div id='activities-section'>
                <div>
                    <FancyTitle firstText={t('activities.title.top')} secondText={t('activities.title.bottom')} color='green' />
                    <div className="text" dangerouslySetInnerHTML={getHtmlString(t('activities.text'))}></div>
                    <Link to='/activites'>{t('misc.see-more')} <i className="lni lni-arrow-right"></i></Link>
                </div>

                <div>
                    <LazyLoadImage src={images.home.activities.first.img} alt={images.home.activities.first.alt} />
                    <LazyLoadImage src={images.home.activities.second.img} alt={images.home.activities.second.alt} />
                </div>
            </div>

            <div id='restaurant-section'>
                <div>
                    <LazyLoadImage src={images.home.restaurant.first.img} alt={images.home.restaurant.first.alt} />
                    <LazyLoadImage src={images.home.restaurant.second.img} alt={images.home.restaurant.second.alt} />
                </div>

                <div>
                    <FancyTitle firstText={t('restaurant.title.top')} secondText={t('restaurant.title.bottom')} color='blue' />
                    <div className="text" dangerouslySetInnerHTML={getHtmlString(t('restaurant.text'))}></div>
                    <Link to='/restaurant'>{t('misc.see-more')} <i className="lni lni-arrow-right"></i></Link>
                </div>
            </div>
        </div>
    )

}

export default Home;