import React from 'react';
import './FancyTitle.css';

function FancyTitle({ firstText, secondText, color }) {

    return (
        <div className={'fancy-title ' + color}>
            <h2>{ firstText }</h2>
            { secondText !== undefined && 
                <h2>{ secondText }</h2>
            }
        </div>
    )
}

export default FancyTitle;