import React, { useState, useEffect, useContext } from 'react';
import './GalleryItem.css';
import { NotificationContext } from '../NotificationProvider/NotificationProvider';
import { v4 } from "uuid";
import axios from "axios";

function GalleryItem({ id, title, file, jsonKey }) {

    const [ imageList, setImageList ] = useState([]);
    const [ imageToUploadList, setImageToUploadList ] = useState([]);

    const [ registering, setRegistering ] = useState(false)

    const dispatch = useContext(NotificationContext)

    useEffect(() => {

        fetch(`${process.env.REACT_APP_API_URL || 'http://localhost:4000'}/api/gallery-item?id=${id}`, {
            headers: {
                'Authorization': 'JWT ' + sessionStorage.getItem('token')
            }
        })
        .then((res) => res.json())
        .then(json => {
            if (json.status === 200) {
                setImageList(json.item.images)
            }
        })

    }, [id, title]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setRegistering(true);

        const data = new FormData()
        data.append("id", id)
        data.append("file", file)
        data.append("key", jsonKey)
        data.append("previousImg", JSON.stringify(imageList))
        for(let i=0; i<imageToUploadList.length; i++) {
            data.append(`img${i}`, imageToUploadList[i].img)
        }

        axios.post(`${process.env.REACT_APP_API_URL || 'http://localhost:4000'}/api/gallery-item/update`, data, {
            headers: {
                'Authorization': 'JWT ' + sessionStorage.getItem('token')
            }
        })
        .then((res) => res.data)
        .then(json => {
            if (json.status === 200) {
                console.log(json.message)
                dispatch({
                    type: "ADD_NOTIFICATION",
                    payload: {
                        id: v4(),
                        type: "SUCCESS",
                        message: `${title} mis à jour`
                    }
                })
            }
            else {
                console.log(json.error)
                dispatch({
                    type: "ADD_NOTIFICATION",
                    payload: {
                        id: v4(),
                        type: "ERROR",
                        message: "Un problème est survenu"
                    }
                })
            }
            setRegistering(false);
        })
        .catch((err) => {
            dispatch({
                type: "ADD_NOTIFICATION",
                payload: {
                    id: v4(),
                    type: "ERROR",
                    message: "Un problème est survenu"
                }
            })
            setRegistering(false);
        })
    }

    const deleteImage = (event) => {
        const selectedImage = event.currentTarget.parentElement.getAttribute("imageurl");

        setImageList(imageList.filter(el => el.img !== selectedImage))
        setImageToUploadList(imageToUploadList.filter(el => el.url !== selectedImage))
    }

    const onChangeHandler = (event) => {
        const image = {
            img: event.currentTarget.files[0],
            url: URL.createObjectURL(event.currentTarget.files[0])
        }

        setImageList([...imageList, { img: image.url, alt: "" }])
        setImageToUploadList([...imageToUploadList, image])
    }

    return (
        <div className="gallery-item">
            <h2 className="title">{ title }</h2>

            <div className="list">
                {
                    imageList.map((image, i) => {
                        return (
                        <div key={i} imageurl={image.img}>
                            <button onClick={deleteImage}><i className="lni lni-close"></i></button>
                            <img src={image.img} alt={image.alt} />
                        </div>
                        )
                    })
                }

                <div className="add-image">
                    <label htmlFor={`input-${id}`}><i className="lni lni-circle-plus"></i></label>
                    <input id={`input-${id}`} type="file" onChange={onChangeHandler} hidden></input>
                </div>
            </div>

            <button onClick={handleSubmit}>{registering ? <i className="lni lni-spinner spinner-icon"></i> : "Enregistrer"}</button>
        </div>
    )

}

export default GalleryItem;