import React, { useState, useEffect, useContext } from 'react';
import './CampingPricesItem.css';
import { NotificationContext } from '../NotificationProvider/NotificationProvider';
import { v4 } from "uuid";

function CampingPricesItem({ id, title, file, jsonKey }) {

    const [ lowSeasonDay, setLowSeasonDay ] = useState("")
    const [ lowSeasonWeek, setLowSeasonWeek ] = useState("")
    const [ highSeasonDay, setHighSeasonDay ] = useState("")
    const [ highSeasonWeek, setHighSeasonWeek ] = useState("")

    const [ registering, setRegistering ] = useState(false)

    const dispatch = useContext(NotificationContext)

    useEffect(() => {

        fetch(`${process.env.REACT_APP_API_URL || 'http://localhost:4000'}/api/camping-prices-item?id=${id}`, {
            headers: {
                'Authorization': 'JWT ' + sessionStorage.getItem('token')
            }
        })
        .then((res) => res.json())
        .then(json => {
            if (json.status === 200) {

                json.item.low.day = Number.isInteger(json.item.low.day) ? json.item.low.day : json.item.low.day.toFixed(2).replace('.', ',')
                json.item.low.week = Number.isInteger(json.item.low.week) ? json.item.low.week : json.item.low.week.toFixed(2).replace('.', ',')
                json.item.high.day = Number.isInteger(json.item.high.day) ? json.item.high.day : json.item.high.day.toFixed(2).replace('.', ',')
                json.item.high.week = Number.isInteger(json.item.high.week) ? json.item.high.week : json.item.high.week.toFixed(2).replace('.', ',')

                setLowSeasonDay(json.item.low.day === 0 ? "" : json.item.low.day)
                setLowSeasonWeek(json.item.low.week === 0 ? "" : json.item.low.week)
                setHighSeasonDay(json.item.high.day === 0 ? "" : json.item.high.day)
                setHighSeasonWeek(json.item.high.week === 0 ? "" : json.item.high.week)
            }
        })
    }, [id, title]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setRegistering(true);

        fetch(`${process.env.REACT_APP_API_URL || 'http://localhost:4000'}/api/camping-prices-item/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'JWT ' + sessionStorage.getItem('token')
            },
            body: `id=${id}&file=${file}&key=${jsonKey}&lowSeasonDay=${encodeURI(lowSeasonDay)}&lowSeasonWeek=${encodeURI(lowSeasonWeek)}&highSeasonDay=${encodeURI(highSeasonDay)}&highSeasonWeek=${encodeURI(highSeasonWeek)}`
        })
        .then((res) => res.json())
        .then(json => {
            if (json.status === 200) {
                console.log(json.message)
                dispatch({
                    type: "ADD_NOTIFICATION",
                    payload: {
                        id: v4(),
                        type: "SUCCESS",
                        message: `${title} mis à jour`
                    }
                })
            }
            else {
                console.log(json.error)
                dispatch({
                    type: "ADD_NOTIFICATION",
                    payload: {
                        id: v4(),
                        type: "ERROR",
                        message: "Un problème est survenu"
                    }
                })
            }
            setRegistering(false);
        })
        .catch((err) => {
            dispatch({
                type: "ADD_NOTIFICATION",
                payload: {
                    id: v4(),
                    type: "ERROR",
                    message: "Un problème est survenu"
                }
            })
            setRegistering(false);
        })
    }

    return (
        <div className="camping-prices-item">
            <h2 className="title">{ title }</h2>

            <p>Basse Saison</p>

            <div className="form">
                <div>
                    <label htmlFor={`basse-saison-jour-${id}`}>Jour</label>
                    <input id={`basse-saison-jour-${id}`} type="text" value={lowSeasonDay} onChange={(event) => setLowSeasonDay(event.target.value)}></input>
                </div>
                <div>
                    <label htmlFor={`basse-saison-semaine-${id}`}>Semaine</label>
                    <input id={`basse-saison-semaine-${id}`} type="text" value={lowSeasonWeek} onChange={(event) => setLowSeasonWeek(event.target.value)}></input>
                </div>
            </div>

            <p>Haute Saison</p>

            <div className="form">
                <div>
                    <label htmlFor={`haute-saison-jour-${id}`}>Jour</label>
                    <input id={`haute-saison-jour-${id}`} type="text" value={highSeasonDay} onChange={(event) => setHighSeasonDay(event.target.value)}></input>
                </div>
                <div>
                    <label htmlFor={`haute-saison-semaine-${id}`}>Semaine</label>
                    <input id={`haute-saison-semaine-${id}`} type="text" value={highSeasonWeek} onChange={(event) => setHighSeasonWeek(event.target.value)}></input>
                </div>
            </div>

            <button onClick={handleSubmit}>{registering ? <i className="lni lni-spinner spinner-icon"></i> : "Enregistrer"}</button>
        </div>
    )

}

export default CampingPricesItem;