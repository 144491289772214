import React, { useState, useEffect, useContext } from 'react';
import './PlacesPricesItem.css';
import { NotificationContext } from '../NotificationProvider/NotificationProvider';
import { v4 } from "uuid";

function PlacesPricesItem({ id, title, file, jsonKey }) {

    const [ standard, setStandard ] = useState("")
    const [ confort, setConfort ] = useState("")
    const [ car, setCar ] = useState("")
    const [ adult, setAdult ] = useState("")
    const [ child, setChild ] = useState("")
    const [ dog, setDog ] = useState("")
    const [ discount, setDiscount ] = useState("")

    const [ registering, setRegistering ] = useState(false)

    const dispatch = useContext(NotificationContext)

    useEffect(() => {

        fetch(`${process.env.REACT_APP_API_URL || 'http://localhost:4000'}/api/camping-prices-item/places?id=${id}`, {
            headers: {
                'Authorization': 'JWT ' + sessionStorage.getItem('token')
            }
        })
        .then((res) => res.json())
        .then(json => {
            if (json.status === 200) {

                json.item.prices.standard = Number.isInteger(json.item.prices.standard) ? json.item.prices.standard : json.item.prices.standard.toFixed(2).replace('.', ',')
                json.item.prices.confort = Number.isInteger(json.item.prices.confort) ? json.item.prices.confort : json.item.prices.confort.toFixed(2).replace('.', ',')
                json.item.extras.car = Number.isInteger(json.item.extras.car) ? json.item.extras.car : json.item.extras.car.toFixed(2).replace('.', ',')
                json.item.extras.adult = Number.isInteger(json.item.extras.adult) ? json.item.extras.adult : json.item.extras.adult.toFixed(2).replace('.', ',')
                json.item.extras.child = Number.isInteger(json.item.extras.child) ? json.item.extras.child : json.item.extras.child.toFixed(2).replace('.', ',')
                json.item.extras.dog = Number.isInteger(json.item.extras.dog) ? json.item.extras.dog : json.item.extras.dog.toFixed(2).replace('.', ',')
                json.item.discount = Number.isInteger(json.item.discount) ? json.item.discount : json.item.discount.toFixed(2).replace('.', ',')

                setStandard(json.item.prices.standard === 0 ? "" : json.item.prices.standard)
                setConfort(json.item.prices.confort === 0 ? "" : json.item.prices.confort)
                setCar(json.item.extras.car === 0 ? "" : json.item.extras.car)
                setAdult(json.item.extras.adult === 0 ? "" : json.item.extras.adult)
                setChild(json.item.extras.child === 0 ? "" : json.item.extras.child)
                setDog(json.item.extras.dog === 0 ? "" : json.item.extras.dog)
                setDiscount(json.item.discount === 0 ? "" : json.item.discount)
            }
        })
    }, [id, title]);

    const handleSubmit = (event) => {
        event.preventDefault();

        fetch(`${process.env.REACT_APP_API_URL || 'http://localhost:4000'}/api/camping-prices-item/update-places`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'JWT ' + sessionStorage.getItem('token')
            },
            body: `id=${id}&file=${file}&key=${jsonKey}&standard=${encodeURI(standard)}&confort=${encodeURI(confort)}&car=${encodeURI(car)}&adult=${encodeURI(adult)}&child=${encodeURI(child)}&dog=${encodeURI(dog)}&discount=${encodeURI(discount)}`
        })
        .then((res) => res.json())
        .then(json => {
            if (json.status === 200) {
                console.log(json.message)
                dispatch({
                    type: "ADD_NOTIFICATION",
                    payload: {
                        id: v4(),
                        type: "SUCCESS",
                        message: `${title} mis à jour`
                    }
                })
            }
            else {
                console.log(json.error)
                dispatch({
                    type: "ADD_NOTIFICATION",
                    payload: {
                        id: v4(),
                        type: "ERROR",
                        message: "Un problème est survenu"
                    }
                })
            }
            setRegistering(false);
        })
        .catch((err) => {
            dispatch({
                type: "ADD_NOTIFICATION",
                payload: {
                    id: v4(),
                    type: "ERROR",
                    message: "Un problème est survenu"
                }
            })
            setRegistering(false);
        })
    }

    return (
        <div className="places-prices-item">
            <h2 className="title">{ title }</h2>

            <div className="form">
                <div>
                    <label htmlFor={`standard-${id}`}>Standard</label>
                    <input id={`standard-${id}`} type="text" value={standard} onChange={(event) => setStandard(event.target.value)}></input>
                </div>
                <div>
                    <label htmlFor={`confort-${id}`}>Confort Electricité</label>
                    <input id={`confort-${id}`} type="text" value={confort} onChange={(event) => setConfort(event.target.value)}></input>
                </div>
            </div>

            <p>Extras</p>

            <div className="form">
                <div>
                    <label htmlFor={`car-${id}`}>Véhicule supplémentaire</label>
                    <input id={`car-${id}`} type="text" value={car} onChange={(event) => setCar(event.target.value)}></input>
                </div>
                <div>
                    <label htmlFor={`adult-${id}`}>Adulte supplémentaire</label>
                    <input id={`adult-${id}`} type="text" value={adult} onChange={(event) => setAdult(event.target.value)}></input>
                </div>
                <div>
                    <label htmlFor={`child-${id}`}>Enfants (-10 ans)</label>
                    <input id={`child-${id}`} type="text" value={child} onChange={(event) => setChild(event.target.value)}></input>
                </div>
                <div>
                    <label htmlFor={`dog-${id}`}>Chien</label>
                    <input id={`dog-${id}`} type="text" value={dog} onChange={(event) => setDog(event.target.value)}></input>
                </div>
            </div>

            <p>Réduction Basse Saison</p>

            <div className="form">
                <div>
                    <label htmlFor={`discount-${id}`}>Pourcentage</label>
                    <input id={`discount-${id}`} type="text" value={discount} onChange={(event) => setDiscount(event.target.value)}></input>
                </div>
            </div>

            <button onClick={handleSubmit}>{registering ? <i className="lni lni-spinner spinner-icon"></i> : "Enregistrer"}</button>
        </div>
    )

}

export default PlacesPricesItem;