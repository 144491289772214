import React, { useState } from 'react';
import './ZoomableImage.css';
import Slideshow from 'react-slidez';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function toggleFullscreen(setFullscreen, fullscreenState) {
    setFullscreen(!fullscreenState);
}

function ZoomableImage({ src, alt }) {

    const [fullscreen, setFullscreen] = useState(false);

    console.log(src);

    return (
        <div className='zoomable-image'>
            <div onClick={() => toggleFullscreen(setFullscreen, fullscreen)}>
                <div className="overlay">
                    <i className="lni lni-zoom-in"></i>
                </div>
                <LazyLoadImage src={src} alt={alt} />
            </div>            

            { fullscreen &&
            <div>
                <Slideshow 
                    showIndex={false}
                    showArrows={false}
                    autoplay={false}
                    slides={[src, src]}
                    effect={'fade'}
                    height={'100%'}
                    width={'100%'}
                />
                <span className='close-button' onClick={() => toggleFullscreen(setFullscreen, fullscreen)}></span>
            </div>}
        </div>
    )
}

export default ZoomableImage;