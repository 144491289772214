import React, { useContext } from 'react';
import './Activities.css';
import Slider from "react-slick";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTranslation } from 'react-i18next';
import { getHtmlString } from '../../utils/parseHtmlString';
import LinkButton from '../LinkButton/LinkButton';
import { ImageContext } from '../ImageProvider/ImageProvider';

function Activities() {

    const { t } = useTranslation('activities');

    const images = useContext(ImageContext);

    const settings = {
        autoplay: true,
        autoplaySpeed: 4000,
        dots: false,
        arrows: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: true
    };

    return (
        <div className='activites'>
            <div className='slider'>
                <Slider {...settings}>
                    {
                        images.activities.slider.map((item, i) => {
                            return (
                                <div>
                                    <img src={item.img} alt={item.alt} />
                                </div>
                            )
                        })
                    }
                </Slider>
            </div>

            <div className="overlay">
                <div>
                    <h1>{t('landing.title.top')}</h1>
                    <h2>{t('landing.title.bottom')}</h2>
                    <LinkButton link='https://www.rondinparc-lozere.com/' text={t('landing.button')} newTab={true} />
                </div>
            </div>

            <div className='description'>
                <LazyLoadImage src={images.activities.logo.img} alt={images.activities.logo.alt} />
                <div className='text' dangerouslySetInnerHTML={getHtmlString(t('content'))}></div>
            </div>
        </div>
    )
}

export default Activities;