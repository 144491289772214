import React from 'react';
import { useTranslation } from 'react-i18next';
import './Footer.css';
import 'flag-icon-css/css/flag-icon.min.css';

function Footer() {

    const { t, i18n } = useTranslation('navbar-footer');

    const changeLanguage = language => {

        i18n.changeLanguage(language);
    
        document.location.reload();
    }

    return (
        <div className='footer'>
            <div className='main'>
                <div className='logo'>
                    <a href='/'><img src='/img/logo_text.png' alt='' /></a>
                </div>

                <div className='links'>
                    <a href='/camping/chalet-bois'>{t('camping-dropdown.chalet-bois')}</a>
                    <a href='/camping/chalet-rondins'>{t('camping-dropdown.chalet-rondin')}</a>
                    <a href='/camping/mobile-home'>{t('camping-dropdown.mobile-home')}</a>
                    <a href='/camping/ecolodges'>{t('camping-dropdown.ecolodge')}</a>
                    <a href='/camping/emplacements'>{t('camping-dropdown.emplacement')}</a>
                    <a href='/restaurant'>{t('tabs.restaurant')}</a>
                    <a href='/activites'>{t('tabs.activities')}</a>
                    <a href='/reserver'>{t('tabs.booking')}</a>
                    <a href='/infos'>{t('tabs.infos')}</a>
                </div>

                <div className='infos'>
                    <p className='info'><i className='lni lni-phone'></i> <a href={`tel:+33${t('phone').substring(1).split(".").join("")}`}>+33 (0){t('phone').substring(1)}</a></p>
                    <p className='info'><i className='lni lni-envelope'></i> <a href={`mailto:${t('mail')}`}>{t('mail')}</a></p>
                    <a className='info' href='https://www.google.fr/maps/place/Rondin+des+Bois+-+camping***/
                    @44.7380852,3.7787902,17z/data=!3m1!4b1!4m5!3m4!1s0x12b4a71c4bcb6cc7:0xb83059d7c699597d!
                    8m2!3d44.7380814!4d3.7809789' target='_blank' rel="noopener noreferrer"><i className='lni lni-map-marker'></i> Palhères - 48300 Rocles</a>
                    <a className='info' href='https://www.facebook.com/Camping-Rondin-des-Bois-333373947081096'
                        target='_blank' rel="noopener noreferrer"><i className='lni lni-facebook'></i> Camping Rondin des Bois</a>
                    <a className='info' href='/cgv'><i className="lni lni-ticket-alt"></i>{t("cgv")}</a>
                </div>
            </div>

            <div className='languages'>
                <span onClick={() => changeLanguage('fr')}><span className="flag-icon flag-icon-fr"></span> Version Française</span>
                <span onClick={() => changeLanguage('en')}><span className="flag-icon flag-icon-gb"></span> English Version</span>
            </div>
        </div>
    )
}

export default Footer;