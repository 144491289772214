import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { getHtmlString } from '../../utils/parseHtmlString';
import './Infos.css';
import FancyTitle from '../FancyTitle/FancyTitle';
import LinkButton from '../LinkButton/LinkButton';
import ZoomableImage from '../ZoomableImage/ZoomableImage';
import { ImageContext } from '../ImageProvider/ImageProvider';

function Infos() {

    const { t } = useTranslation('infos');

    const images = useContext(ImageContext);

    return (
        <div className='infos'>
            <div className='banner'>
                <img src='/img/chalet_restaurant.jpg' alt='' />

                <div className='overlay'>
                    <div>
                        <h1>{t('title.top')}</h1>
                        <h2>{t('title.bottom')}</h2>
                    </div>
                </div>
            </div>

            <div className='content'>
                <FancyTitle firstText={t('contact.title.top')} secondText={t('contact.title.bottom')} color='blue' />
                <div>
                    <p><div dangerouslySetInnerHTML={getHtmlString(t('contact.phone'))}></div><i className='lni lni-phone background-icon'></i></p>
                    <p><div dangerouslySetInnerHTML={getHtmlString(t('contact.mail'))}></div><i className='lni lni-envelope background-icon'></i></p>
                </div>

                <FancyTitle firstText={t('join.title.top')} secondText={t('join.title.bottom')} color='red' />
                <div>
                    <p dangerouslySetInnerHTML={getHtmlString(t('join.text'))}></p>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55509.134631947236!2d3.738490965874208!3d44.73412967728671!2m3!1f0!
                        2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12b4a71c4bcb6cc7%3A0xb83059d7c699597d!2sRondin+des+Bois+-+camping*2A*2A*2A!5e0!3m2!1sfr!2s
                        fr!4v1494351268373" allowFullScreen="" title='Adresse Google Map'></iframe>
                </div>

                <FancyTitle firstText={t('actualities.title.top')} color='green' />
                <div>
                    <div dangerouslySetInnerHTML={getHtmlString(t('actualities.text'))}></div>
                    <div>
                        <LinkButton link={t('actualities.link')} text={t('actualities.button')} newTab={true} color='green' />
                    </div>
                </div>

                <FancyTitle firstText={t('map.title.top')} secondText={t('map.title.bottom')} color='blue' />
                <div>
                    <ZoomableImage src={images.infos.map.img} alt={images.infos.map.alt} />
                </div>
            </div>
        </div>
    )
}

export default Infos;