import React, { useEffect, useState } from 'react';
import './Notification.css';

function Notification({ dispatch, id, type, message }) {

    const [ intervalId, setIntervalId ] = useState(null);
    const [ exit, setExit ] = useState(false);
    const [ notifTimeout, setNotifTimeout ] = useState(0);

    const handleStartTimer = () => {
        const interval = setInterval(() => {
            setNotifTimeout(prev => {
                if (prev < 100) {
                    return prev + 0.5;
                }

                clearInterval(intervalId)
                return prev;
            })
        }, 20)

        setIntervalId(interval)
    }

    const handleCloseNotification = () => {
        clearInterval(intervalId)
        setExit(true)
        setTimeout(() => {
            dispatch({
                type: "REMOVE_NOTIFICATION",
                id: id
            })
        }, 400)
    }

    useEffect(() => {
        if (notifTimeout === 100) {
            handleCloseNotification()
        }
    }, [notifTimeout])

    useEffect(() => {
        handleStartTimer()
    }, [])

    return (
        <div className={`notification ${type === "SUCCESS" ? "success" : "error"} ${exit ? "exit" : ""}`}>
            <i className={`lni lni-${type === "SUCCESS" ? "checkmark" : "close"}`}></i>
            <div>
                <p className="title">{type === "SUCCESS" ? "Tout s'est bien déroulé !" : "Il y a eu un problème..."}</p>
                <p>{ message }</p>
            </div>
        </div>
    )

}

export default Notification;