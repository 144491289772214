import React, { useState } from 'react';
import {
    Link
} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import './Dropdown.css';

function Dropdown({ mainNavOpen, setMainNavOpen }) {

    const [open, setOpen] = useState(false);

    const { t } = useTranslation('navbar-footer');

    return (
        <div className={'dropdown' + (open ? ' open' : '')} onClick={() => setOpen(!open)}>
            <button className='dropdown-button'>{t('tabs.camping')}</button>
            <div className='dropdown-content'>
                <Link to="/camping/chalet-bois" onClick={(mainNavOpen ? () => setMainNavOpen(false) : null)}>{t('camping-dropdown.chalet-bois')}</Link>
                <Link to="/camping/chalet-rondins" onClick={(mainNavOpen ? () => setMainNavOpen(false) : null)}>{t('camping-dropdown.chalet-rondin')}</Link>
                <Link to="/camping/mobile-home" onClick={(mainNavOpen ? () => setMainNavOpen(false) : null)}>{t('camping-dropdown.mobile-home')}</Link>
                <Link to="/camping/ecolodges" onClick={(mainNavOpen ? () => setMainNavOpen(false) : null)}>{t('camping-dropdown.ecolodge')}</Link>
                <Link to="/camping/emplacements" onClick={(mainNavOpen ? () => setMainNavOpen(false) : null)}>{t('camping-dropdown.emplacement')}</Link>
            </div>
        </div>
    )
}

export default Dropdown;