import React, { useState, useEffect, useContext } from 'react';
import './InfosItem.css';
import { NotificationContext } from '../NotificationProvider/NotificationProvider';
import { v4 } from "uuid";

function InfosItem({ id, title, file, jsonKey }) {

    const [ phone, setPhone ] = useState("")
    const [ mail, setMail ] = useState("")
    const [ actuUrl, setActuUrl ] = useState("")

    const [ registering, setRegistering ] = useState(false)

    const dispatch = useContext(NotificationContext)

    useEffect(() => {

        fetch(`${process.env.REACT_APP_API_URL || 'http://localhost:4000'}/api/infos-item?id=${id}`, {
            headers: {
                'Authorization': 'JWT ' + sessionStorage.getItem('token')
            }
        })
        .then((res) => res.json())
        .then(json => {
            if (json.status === 200) {

                setPhone(json.item.contact.phone)
                setMail(json.item.contact.mail)
                setActuUrl(json.item.actu)
            }
        })
    }, [id, title]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setRegistering(true);

        fetch(`${process.env.REACT_APP_API_URL || 'http://localhost:4000'}/api/infos-item/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'JWT ' + sessionStorage.getItem('token')
            },
            body: `id=${id}&file=${file}&key=${jsonKey}&phone=${encodeURI(phone)}&mail=${encodeURI(mail)}&actu=${encodeURI(actuUrl)}`
        })
        .then((res) => res.json())
        .then(json => {
            if (json.status === 200) {
                console.log(json.message)
                dispatch({
                    type: "ADD_NOTIFICATION",
                    payload: {
                        id: v4(),
                        type: "SUCCESS",
                        message: `${title} mis à jour`
                    }
                })
            }
            else {
                console.log(json.error)
                dispatch({
                    type: "ADD_NOTIFICATION",
                    payload: {
                        id: v4(),
                        type: "ERROR",
                        message: "Un problème est survenu"
                    }
                })
            }
            setRegistering(false);
        })
        .catch((err) => {
            dispatch({
                type: "ADD_NOTIFICATION",
                payload: {
                    id: v4(),
                    type: "ERROR",
                    message: "Un problème est survenu"
                }
            })
            setRegistering(false);
        })
    }

    return (
        <div className="infos-item">
            <h2 className="title">{ title }</h2>

            <p>Contact</p>

            <div className="form">
                <div>
                    <label htmlFor={`phone-${id}`}>Téléphone</label>
                    <input id={`phone-${id}`} type="text" value={phone} onChange={(event) => setPhone(event.target.value)}></input>
                </div>
                <div>
                    <label htmlFor={`mail-${id}`}>Email</label>
                    <input id={`mail-${id}`} type="text" value={mail} onChange={(event) => setMail(event.target.value)}></input>
                </div>
            </div>

            <p>Actualités</p>

            <div className="form">
                <div>
                    <label htmlFor={`actu-${id}`}>Lien vers les Rendez-Vous de l'Office de Tourisme</label>
                    <input id={`actu-${id}`} type="text" value={actuUrl} onChange={(event) => setActuUrl(event.target.value)}></input>
                </div>
            </div>

            <button onClick={handleSubmit}>{registering ? <i className="lni lni-spinner spinner-icon"></i> : "Enregistrer"}</button>
        </div>
    )

}

export default InfosItem;