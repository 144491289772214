import React, { createContext, useEffect, useState } from 'react';

export const ImageContext = createContext();

function ImageProvider(props) {

    const [ images, setImages ] = useState(null)
    const [ loading, setLoading ] = useState(true)

    useEffect(() => {
        
        fetch("/img/urls.json")
        .then((res) => res.json())
        .then(json => {
            setImages(json)
            setLoading(false)
        })

    }, [])

    return (
        <ImageContext.Provider value={images}>
            { loading ? <div></div> : props.children }
        </ImageContext.Provider>
    )

}

export default ImageProvider;