export function getHtmlString(string) {
    return {
        __html: parseHtmlString(string)
    };
}

function parseHtmlString(string) {
    const regex = /<\s*\/?\s*script\b[^>]*>/gim;

    return (regex.test(string) ? '' : string);
}