import React, { Suspense } from 'react';
import {
    BrowserRouter as Router
} from "react-router-dom";
import './App.css';
import Container from '../Container/Container';

function App() {

    return (
        /*<Suspense fallback={<Spinner />}>*/
        <Suspense fallback=''>
            <Router>
                <Container />
            </Router>
        </Suspense>
    );
}

export default App;
