import React from 'react';
import './MenuEntry.css';

function MenuEntry({ firstText, secondText, alignment }) {

    return (
        <div className={'menu-entry ' + alignment}>
            <p>{firstText}</p>
            <p>{secondText}</p>
        </div>
    )
}

export default MenuEntry;