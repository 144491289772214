import React from 'react';
import './LinkButton.css';

function LinkButton({ link, text, color, newTab }) {

    return (
        <a className={'button ' + color} href={link} target={(newTab ? '_blank' : '')} rel={(newTab ? "noopener noreferrer" : '')}>{text}</a>
    );
}

export default LinkButton;
